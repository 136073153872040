import React from "react";
import { login } from "../../services/auth.js";
import api from "../../services/api.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import logo from "assets/img/logo-cuborh.png";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);
export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = React.useState(``);
  const [password, setPassword] = React.useState(``);
  const [controlClick, setControlClick] = React.useState(false)

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  function salvarEmail(event){
     setEmail(event.target.value)
  }
  function salvarPass(event){
    setPassword(event.target.value)
  }
  
  function validarUsuario(user){
    const admin = user.is_admin
    const master = user.is_master
    if(admin === 1 && master === 0){
      localStorage.setItem('userLogged',"UsuarioAdmin")
    }
    else if(admin === 0 && master === 1){
      localStorage.setItem('userLogged',"UsuarioMaster")
    }
    else if(admin === 0 && master === 0){
      localStorage.setItem('userLogged',"UsuarioPadrao")
    }
    else{
      localStorage.setItem('userLogged',"UsuarioTotal")
    }
    startProject()
  }

  async function log(){
    setControlClick(true)
    if(controlClick){
      return 0;
    }
    try{
      toast.info("Realizando log-in")
      const response = await api.post("/account/signin",{email:email,password:password})
      login(response.data.token)
      const userLogged = await api.get("/account/identify")
      const usuario = userLogged.data
      validarUsuario(usuario)
      toast.success("Login Realizado")
      setControlClick(false)
    }catch(error){
      console.log(error);
      toast.error("Ocorreu um erro ao tentar logar")
      setControlClick(false)
          
    }
  }



  async function startProject(){
    if(localStorage.getItem('userLogged') === 'UsuarioMaster'){
      var dashroutes = [{
        path: "/cadastroEmpresa",
        name: "Cadastro de Empresas",
        icon: 'DashboardIcon',
        component: 'Cadastro de Empresas',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/cadastroMenus",
        name: "Cadastro de Menus",
        icon: 'DashboardIcon',
        component: 'Cadastro de Menus',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/cadastroUsuario",
        name: "Cadastro de Usuarios",
        icon: 'DashboardIcon',
        component: 'Cadastro de Usuarios',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/menuHistoricoMachineLearning",
        name: "Histórico Machine Learning",
        icon: 'DashboardIcon',
        component: 'menuHistoricoMachineLearning',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/redirectUser",
        name: 'Redirect User',
        icon: 'DashboardIcon',
        component: 'Redirect User',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/updatePassword",
        name: 'Atualizar Senha',
        icon: 'DashboardIcon',
        component: 'updatePassword',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: 'LoginPage',
        layout: "/auth",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "auth",
        name: "Logout",
        icon: 'Apps',
        layout: "/",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      }]
        localStorage.setItem('vetor', JSON.stringify(dashroutes))
        setTimeout(()=>(window.location="/admin/cadastroEmpresa", 3000))
    }
    else if(localStorage.getItem('userLogged') ==='UsuarioAdmin'){
      var rotasusertotal = [{
        path: "/menuMachineLearning",
        name: "Machine Learning",
        icon: 'DashboardIcon',
        component: 'menuMachineLearning',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/menuAdmin",
        name: "Upload de Arquivos",
        icon: 'DashboardIcon',
        component: 'Menu',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/restringirMenus",
        name: "Menus",
        icon: 'DashboardIcon',
        component: 'Restringir Menus',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      },{
        path: "/updatePassword",
        name: 'Atualizar Senha',
        icon: 'DashboardIcon',
        component: 'updatePassword',
        layout: "/admin",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'IframeMenus',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      }]
      let vetorMenusSide = []
      try{
        const response = await api.get("/admin/menus")
        const menus = response.data.menus
        let menusFilhos = []
        var dashroutes = []
        for(var i=0;i<menus.length;i++){
          menusFilhos.push(menus[i].sub_menus)
          vetorMenusSide.push({nome:menus[i].name,restrict:false,
            id:menus[i].id,parent_id:null})
          dashroutes.push({
            path:`/${menus[i].name}`,
            name:menus[i].name,
            icon: 'DashboardIcon',
            component: 'IframeMenus',
            layout: "/admin",
            subAbas: []
          })
          for(var count =0; count<menus[i].sub_menus.length;count++){
            vetorMenusSide.push({nome:menus[i].sub_menus[count].name,restrict:false,
              id:menus[i].sub_menus[count].id,parent_id:menus[i].sub_menus[count].parent_id})
            dashroutes[i].subAbas.push({
              path:`/${menus[i].sub_menus[count].name}`,
              name:menus[i].sub_menus[count].name,
              icon: 'DashboardIcon',
              component: 'IframeMenus',
              layout: "/admin",
              subAbas: []
            })
            for(var cont =0; cont<menus[i].sub_menus[count].sub_menus.length;cont++){
              vetorMenusSide.push({nome:menus[i].sub_menus[count].sub_menus[cont].name,
                restrict:false,id:menus[i].sub_menus[count].sub_menus[cont].id,
                parent_id:menus[i].sub_menus[count].sub_menus[cont].parent_id})
              dashroutes[i].subAbas[count].subAbas.push({
                path:`/${menus[i].sub_menus[count].sub_menus[cont].name}`,
                name:menus[i].sub_menus[count].sub_menus[cont].name,
                icon: 'DashboardIcon',
                component: 'IframeMenus',
                layout: "/admin"
              })
            }
          }
        }
        dashroutes.push({
          path: "/login-page",
          name: "Login Page",
          rtlName: "هعذاتسجيل الدخول",
          mini: "L",
          rtlMini: "هعذا",
          component: 'LoginPage',
          layout: "/auth",
          subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin",
                    subAbas: [{
                      path: "/workday",
                      name:'empty',
                      icon: 'DashboardIcon',
                      component: 'IframeMenus',
                      layout: "/admin",
                    }]
                    },]
        },{
          path: "auth",
          name: "Logout",
          icon: 'Apps',
          layout: "/",
          subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin",
                    subAbas: [{
                      path: "/workday",
                      name:'empty',
                      icon: 'DashboardIcon',
                      component: 'IframeMenus',
                      layout: "/admin",
                    }]
                    },]
        })
      }catch(error){
        console.log(error)
      }
      for(var t =0; t<dashroutes.length;t++){
        rotasusertotal.push(dashroutes[t])
      }
        localStorage.setItem('vetor', JSON.stringify(rotasusertotal))
        localStorage.setItem('vetorMenus', JSON.stringify(vetorMenusSide))
        setTimeout(()=>(window.location="/admin/menuAdmin", 3000))
    }
    else{
      try{
        const response = await api.get('admin/user-menu-access')
        const menus= response.data.menus
        const menusFilhos = []
        var dashroutes = []
        for(var i=0;i<menus.length;i++){
          menusFilhos.push(menus[i].sub_menus)
          dashroutes.push({
            path:`/${menus[i].name}`,
            name:menus[i].name,
            icon: 'DashboardIcon',
            component: 'IframeMenus',
            layout: "/admin",
            subAbas: []
          })
          for(var count =0; count<menus[i].sub_menus.length;count++){
            dashroutes[i].subAbas.push({
              path:`/${menus[i].sub_menus[count].name}`,
              name:menus[i].sub_menus[count].name,
              icon: 'DashboardIcon',
              component: 'IframeMenus',
              layout: "/admin",
              subAbas: []
            })
            for(var cont =0; cont<menus[i].sub_menus[count].sub_menus.length;cont++){
              dashroutes[i].subAbas[count].subAbas.push({
                path:`/${menus[i].sub_menus[count].sub_menus[cont].name}`,
                name:menus[i].sub_menus[count].sub_menus[cont].name,
                icon: 'DashboardIcon',
                component: 'IframeMenus',
                layout: "/admin"
              })
            }
          }
        }
        dashroutes.push({
          path: "/updatePassword",
          name: 'Atualizar Senha',
          icon: 'DashboardIcon',
          component: 'updatePassword',
          layout: "/admin",
          subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin",
                    subAbas: [{
                      path: "/workday",
                      name:'empty',
                      icon: 'DashboardIcon',
                      component: 'IframeMenus',
                      layout: "/admin"
                    }]
                    },]
        },{
          path: "/login-page",
          name: "Login Page",
          rtlName: "هعذاتسجيل الدخول",
          mini: "L",
          rtlMini: "هعذا",
          component: 'LoginPage',
          layout: "/auth",
          subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin",
                    subAbas: [{
                      path: "/workday",
                      name:'empty',
                      icon: 'DashboardIcon',
                      component: 'IframeMenus',
                      layout: "/admin",
                    }]
                    },]
        },{
          path: "auth",
          name: "Logout",
          icon: 'Apps',
          layout: "/",
          subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin",
                    subAbas: [{
                      path: "/workday",
                      name:'empty',
                      icon: 'DashboardIcon',
                      component: 'IframeMenus',
                      layout: "/admin",
                    }]
                    },]
        })
        localStorage.setItem('vetor', JSON.stringify(dashroutes))
      if(dashroutes[0].subAbas.length>0){
        if(dashroutes[0].subAbas[0].subAbas.length>0){
          setTimeout(()=>(window.location=`/admin${dashroutes[0].path}${dashroutes[0].subAbas[0].path}${dashroutes[0].subAbas[0].subAbas[0].path}`, 3000))
        }
        else{
          setTimeout(()=>(window.location=`/admin${dashroutes[0].path}${dashroutes[0].subAbas[0].path}`, 3000))
        }
      }
      else{
        setTimeout(()=>(window.location=`/admin${dashroutes[0].path}`, 3000))
      }
      }catch(error){
        console.log(error)
      }
    }
  }
  return (
    <div className={classes.container}>
    <ToastContainer/>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                <img src={logo} width="75%" />
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange:(
                      salvarEmail
                    )
                  }
                }
                />
                <CustomInput
                  labelText="Senha"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    onChange:(
                      salvarPass
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" simple size="lg" block onClick={()=>
                    log()
                  }>
                  Entrar
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}