import axios from "axios";
import { getToken } from "./auth";


const api = axios.create({
  //baseURL: "http://18.228.24.73/"
  //baseURL: "http://192.168.205.20:8081/"
  baseURL: "https://api.cubodorh.com.br/"
  //baseURL: "http://localhost:8080/"
});

api.interceptors.request.use(async config => {
  const token = getToken();

  if (token){
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
})

export default api;
