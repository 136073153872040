import React from "react";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components

// @material-ui/icons

// core components
export default function HeaderLinks(props) {
  return (
    <div>
      
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
