import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import './machineLearning.css'
import api from "../../services/api"
import ReactTables from "../Tables/ReactTables"

export default function MachineLearning(props) {
    const [data, setData] = React.useState([])
    const [tableCompany, setTableCompany] = React.useState(true)
    const [tableHistory, setTableHistory] = React.useState(false)
    const [optionsFilter, setOptionsFilter] = React.useState([])

    async function carregarDados() {
        console.log(props)
        if (props.location.state !== undefined) {
            const data = props.location.state
            setTableCompany(false)
            setTableHistory(true)
            try {
                const response = await api.get(`/admin/executions-ml?corp_id=${data.id}`)
                setData(response.data)
                const optionsAux = []
                for (let i = 0; i < response.data.length; i++) {
                    let bool = false
                    for (let y = 0; y < optionsAux.length; y++) {
                        if (response.data[i].model_id.name === optionsAux[y]) {
                            bool = true
                        }
                    }
                    if (!bool) {
                        optionsAux.push(response.data[i].model_id.name)
                    }
                }
                setOptionsFilter(optionsAux)
            } catch (err) {
                console.log(err)
            }
        } else {
            setTableCompany(true)
            setTableHistory(false)
            try {
                const response = await api.get('/admin/corps')
                setData(response.data.corps)
                console.log(response)
            } catch (err) {
                console.log(err)
            }
        }
    }
    const customFilter = ({ filter, onChange }) => {
        return (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option value="all">Exibir Todos</option>
                {optionsFilter
                    .filter((item, i, s) => s.lastIndexOf(item) == i)
                    .map(function (value) {
                        if (value === data[data.length - 1]) {
                            return null
                        }
                        return (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        );
                    })}
            </select>
        );
    };

    React.useEffect(() => {
        carregarDados()
    }, [props.location.state])

    return (
        <div>
            <ToastContainer />
            <div style={{ display: tableCompany ? "block" : "none" }}>
                <ReactTables
                    size={10}
                    data={data}
                    columns={[
                        {
                            Header: "Empresa",
                            accessor: "empresa"
                        },
                        {
                            Header: "Actions",
                            accessor: "actions",
                            sortable: false,
                            filterable: false
                        }
                    ]}
                    nome="Histórico Machine Learning master"
                />
            </div>
            <div style={{ display: tableHistory ? "block" : "none" }}>
                <ReactTables
                    data={data}
                    columns={[
                        {
                            Header: "Data",
                            accessor: "data",
                            width: 150
                        }, {
                            Header: "Tipo de modelo",
                            accessor: "TipoDeModelo",
                            width: 150,
                            sortable: false,
                            filterMethod: (filter, row) => {
                                if (filter.value === 'all') {
                                    return true;
                                }
                                return row[filter.id] === filter.value;
                            },
                            Filter: ({ filter, onChange }) =>
                                customFilter({ filter, onChange })
                        },
                        {
                            Header: "Resposta",
                            accessor: "resposta",
                        },
                        {
                            Header: "",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            width: 1
                        }
                    ]}
                    nome="Histórico Machine Learning"
                />
            </div>
        </div>
    )
}