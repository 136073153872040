import React from "react";
// @material-ui/core components
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


export default function Workday() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
        {//<iframe width="100%" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=af16de94-c0c8-4daa-ad59-5e021d637c3d&autoAuth=true&ctid=efe3602e-ccd7-42ea-b155-ada1203ae4ff&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
        }
        </GridItem>
      </GridContainer>
    </div>
  );
}
