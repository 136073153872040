import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api.js"
import { login } from "../../services/auth.js"
// core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTables from "../Tables/ReactTables"
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const styles2 = {
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },
    container:{
      height:"500px",
      width:"100%"
    }
  }
  const useStyles = makeStyles(styles);
  const useStyles2 = makeStyles(styles2);

export default function Usuarios(){
    const [bool, setBool] = React.useState(false)
    const [dados, setDados] = React.useState([])
    const [empresasComID, setEmpresasComID] = React.useState([])
    const [empresas, setEmpresas] = React.useState([])
    const [empresasChoose, setEmpresasChoose] = React.useState("Selecione uma empresa")
    const classes = useStyles();
    const classes2 = useStyles2();

    async function incializar(){
        try{
            const response = await api.get("/admin/users")
            const response2 = await api.get("/admin/users?is_admin=1")
            var aux = []
            const masters = response.data.users
            const admins = response2.data.users
            for(var count = 0; count<masters.length;count++){
                if(count === 0){
                    aux.push({name:"Adicionar Novo", admin:null, master:null})
                  }
                aux.push({nome:masters[count].name,name:masters[count].email, admin: masters[count].is_admin === 0 ? "Não" : "Sim", master:masters[count].is_master === 0 ? "Não" : "Sim", celular:masters[count].phone, cpf:masters[count].cpf, id:masters[count].id,corp_id:masters[count].corp_id})
            }
            for(var contador = 0; contador<admins.length;contador++){
                aux.push({nome:admins[contador].name,name:admins[contador].email, admin: admins[contador].is_admin === 0 ? "Não" : "Sim", master:admins[contador].is_master === 0 ? "Não" : "Sim", celular:admins[contador].phone, cpf:admins[contador].cpf,id:admins[contador].id,corp_id:admins[contador].corp_id})
            }
            if(aux.length === 0){
                aux.push({name:"Adicionar Novo", parent_id:null,pos:0,sub_menus:[], telefone:null, celular:null, cpf:null})
              }
            setDados(aux)
        }catch(error){
            console.log(error)
        }
        try{
          var aux3 = []
          var vetAux2 = [{name:null,id:null}]
          const response = await api.get("/admin/corps")
          const vetor = response.data.corps
          for(var cot=0;cot<vetor.length;cot++){
            aux3.push(vetor[cot].name)
          }
          for(var cont=0;cont<vetor.length;cont++){
            vetAux2.push({name:vetor[cont].name, id:vetor[cont].id})
          }
          setEmpresasComID(vetAux2)
          setEmpresas(aux3)
        }catch(err){
          console.log(err)
        }
    }

    React.useEffect(()=>{
        incializar()
    },[bool])

    const [openTelaCad, setOpenTelaCad] = React.useState(false)
    function handleCloseTelaCad(){
      setOpenTelaCad(false)
    }
    function handleOpenTelaCad (){
      setOpenTelaCad(true)
    }


    const [novoUsario, setNovoUsuario] = React.useState({
      nome:null,
      email:null,
      cpf:'',
      celular:'',
      password:null,
      is_admin:0,
      is_master:0
    })

    const [updateUser, setUpdateUser] = React.useState({
      nome:null,
      email:null,
      cpf:'',
      celular:'',
      password:null,
      is_admin:0,
      is_master:0,
      corp_id:null
    })



    function salvarNomeUsuario(event){
      setNovoUsuario({
        nome:event.target.value,
        email:novoUsario.email,
        password:novoUsario.password,
        cpf:novoUsario.cpf,
        celular:novoUsario.celular,
        is_master:0,
        is_admin:0
      })
    }
    
    function salvarEmailUsuario(event){
      setNovoUsuario({
        nome:novoUsario.nome,
        email:event.target.value,
        password:novoUsario.password,
        cpf:novoUsario.cpf,
        celular:novoUsario.celular,
        is_master:0,
        is_admin:0
      })
    }
    const cpfMask = value => {
      return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
    function salvarCpfUsuario(event){
      let mask = cpfMask(event.target.value)
      setNovoUsuario({
        nome:novoUsario.nome,
        email:novoUsario.email,
        password:novoUsario.password,
        cpf:mask,
        celular:novoUsario.celular,
        is_master:0,
        is_admin:0
      })
    }
    const celMask = value => {
      return value
        /*.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '(\$1)\$2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')*/
        }
    function salvarCelularUsuario(event){
      let mask = celMask(event.target.value)
      setNovoUsuario({
        nome:novoUsario.nome,
        email:novoUsario.email,
        password:novoUsario.password,
        cpf:novoUsario.cpf,
        celular:mask,
        is_master:0,
        is_admin:0
      })
    }
    function salvarPasswordUsuario(event){
      setNovoUsuario({
        nome:novoUsario.nome,
        email:novoUsario.email,
        password:event.target.value,
        cpf:novoUsario.cpf,
        celular:novoUsario.celular,
        is_master:0,
        is_admin:0
      })
    }
    function salvarEditNomeUser(event){
      setUpdateUser({
        nome:event.target.value,
        email:updateUser.email,
        password:updateUser.password,
        celular:updateUser.celular,
        is_master:updateUser.is_master,
        is_admin:updateUser.is_admin,
        cpf:updateUser.cpf,
        corp_id:updateUser.corp_id
      })
    }
    function salvarEditEmailUser(event){
      setUpdateUser({
        nome:updateUser.nome,
        email:event.target.value,
        password:updateUser.password,
        celular:updateUser.celular,
        is_master:updateUser.is_master,
        is_admin:updateUser.is_admin,
        cpf:updateUser.cpf,
        corp_id:updateUser.corp_id
      })
    }
    function salvarEditCelularUser(event){
      
      setUpdateUser({
        nome:updateUser.nome,
        email:updateUser.email,
        password:updateUser.password,
        celular:event.target.value,
        is_master:updateUser.is_master,
        is_admin:updateUser.is_admin,
        cpf:updateUser.cpf,
        corp_id:updateUser.corp_id
      })
    }
    function salvarEditCpfUser(event){
      setUpdateUser({
        nome:updateUser.nome,
        email:updateUser.email,
        password:updateUser.password,
        celular:updateUser.celular,
        is_master:updateUser.is_master,
        is_admin:updateUser.is_admin,
        cpf:cpfMask(event.target.value),
        corp_id:updateUser.corp_id
      })
    }
    function funcaoRedirect(id){
      
      window.location=`/admin/redirectUser?id=${id}`
      
    }
    async function submeter(){
      var admin; 
      var master;
      if(usuariotypeSelect === 0){
        admin = 1;
        master = 0;
      }
      else if(usuariotypeSelect === 1){
        admin = 0;
        master = 1;
      }
      else if(usuariotypeSelect === null){
        admin = updateUser.is_admin;
        master = updateUser.is_master;
      }
      else{
        admin = 0;
        master = 0;
      }
      const arroba = '@'
      var erroEmail = false
      var erroPassword = false
      if(novoUsario.nome == null || novoUsario.email == null || novoUsario.password == null){
        toast.error("Verifique se todos os campos estão preenchidos")
      }
      else{
        if(novoUsario.password.length<8){
          erroPassword = true
        }
        if(novoUsario.email.includes(arroba) === false){
          erroEmail = true 
        }
        if(erroEmail){
          toast.error("Verifique se o email está correto")
        }
        else if(erroPassword){
          toast.error("Senha precisa ser maior que 8 digitos")
        }
        else{
          try{
            if(empresasChoose === "Selecione uma empresa" && empresasChoose !== 'Nenhuma'){
              await api.post("/admin/create-user", {name:novoUsario.nome, is_admin:admin, is_master:master, email:novoUsario.email, password:novoUsario.password.toString(), cpf:novoUsario.cpf === '' ? null : novoUsario.cpf,phone:novoUsario.celular})
            }
            else{
              for(var i = 0; i<empresasComID.length;i++){
                if(empresasComID[i].name === empresasChoose){
                  await api.post("/admin/create-user", {name:novoUsario.nome, is_admin:admin, is_master:master, email:novoUsario.email, password:novoUsario.password.toString(), cpf:novoUsario.cpf === '' ? null : novoUsario.cpf,phone:novoUsario.celular, corp_id:empresasComID[i].id})
                }
              }
            }
            handleCloseTelaCad()
            setBool(!bool)
            toast.success("Usuario Criado com sucesso")
          }catch(err){
            console.log(err)
            if(err.response.data.errors !== undefined && err.response.data.errors !== null){
              if(err.response.data.errors[0] !== undefined && err.response.data.errors[0] !== null){
                toast.error(`${err.response.data.errors[0]}`)
              } 
            }
          }
        }
      }
    }

    async function delet(id){
      try{
        toast.info("Deletando usuario")
        await api.delete(`/admin/delete-user?id=${id}`)
        setBool(!bool)
        toast.success("Usuario deletado com sucesso")
      }catch(err){
        console.log(err)
        toast.error("Ocorreu um erro ao deletar o usuario");
      }
    }

    const [openTelaEdit, setOpenTelaEdit] = React.useState(false)
    function handleCloseTelaEdit(){
      setOpenTelaEdit(false)
    }

    const [userID, setUserID] = React.useState();
    function handleOpenTelaEdit (id){
      setUserID(id)
      for(var j=0;j<dados.length;j++){
        if(id === dados[j].id){
          setUpdateUser({
            nome:dados[j].nome,
            email:dados[j].name,
            cpf:dados[j].cpf,
            is_admin:dados[j].admin === 'Sim' ? 1 :0,
            is_master:dados[j].master === 'Sim' ? 1 :0,
            corp_id:dados[j].corp_id
          })
        }
      }
      setOpenTelaEdit(true)
    }
    const [novaSenha, setNovaSenha] = React.useState('');
    const [confirmarNovaSenha, setConfirmarNovaSenha] = React.useState('')
    function salvarNovaSenha(event){
      setNovaSenha(event.target.value)
    }
    function SalvarConfirmarNovaSenha(event){
      setConfirmarNovaSenha(event.target.value)
    }
    async function alterar(){
      if(novaSenha.length>0){
        if(novaSenha.length<8){
          toast.error("Senha precisa ser maior que 8")
        }
        else if(novaSenha !== confirmarNovaSenha){
          toast.error("Senhas não são iguais")
        }
        else{
          let autoEdit = false
          try{
            const userLogged = await api.get("/account/identify")
            const usuario = userLogged.data.id
            if(usuario === userID)
              autoEdit = true
          }catch(error){
            console.log(error)
          }
          if(autoEdit){
            try{
              toast.info("Atualizando Dados")
              //updatePassword do user logado
              const response = await api.post(`/account/update-password-with-token`,{
                newPassword:novaSenha
              })
              login(response.data.token)
              try{
                toast.info("Atualizando Dados")
                if(empresasChoose !== 'Selecione uma empresa' && empresasChoose !== 'Nenhuma'){
                  for(var i = 0; i<empresasComID.length;i++){
                    if(empresasComID[i].name === empresasChoose){
                      var admin;
                      var master;
                      if(usuariotypeSelect === 0){
                        admin = 1;
                        master = 0;
                      }
                      else if(usuariotypeSelect === 1){
                        admin = 0;
                        master = 1;
                      }
                      else if(usuariotypeSelect == null){
                        admin = updateUser.is_admin;
                        master = updateUser.is_master;
                      }
                      else{
                        admin = 0;
                        master = 0;
                      }
                      await api.post(`/admin/update-user?id=${userID}`,{
                        name:updateUser.nome,
                        email:updateUser.email,
                        is_admin:admin,
                        is_master:master,
                        phone:updateUser.phone,
                        cpf:updateUser.cpf,
                        corp_id:empresasComID[i].id
                      })
                      toast.success("Dados Salvos")
                      setBool(!bool)
                      handleCloseTelaEdit()
                    }
                  }
                }
                else{
                  var admin;
                  var master;
                  if(usuariotypeSelect === 0){
                    admin = 1;
                    master = 0;
                  }
                  else if(usuariotypeSelect === 1){
                    admin = 0;
                    master = 1;
                  }
                  else if(usuariotypeSelect == null){
                    admin = updateUser.is_admin;
                    master = updateUser.is_master;
                  }
                  else{
                    admin = 0;
                    master = 0;
                  }
                  await api.post(`/admin/update-user?id=${userID}`,{
                    name:updateUser.nome,
                    email:updateUser.email,
                    is_admin:admin,
                    is_master:master,
                    phone:updateUser.celular,
                    cpf:updateUser.cpf,
                    corp_id:updateUser.corp_id
                  })
                  toast.success("Dados Salvos")
                  setBool(!bool)
                  handleCloseTelaEdit()
                }
                  }catch(err){
                    console.log(err)
                    toast.error("Houve um erro ao salvar os dados")
                  }
            }catch(error){
              console.log(error)
            }
          }
          else{
            try{
              await api.post(`/admin/update-password?id=${userID}`,{
                newPassword:novaSenha
              })
              try{
                toast.info("Atualizando Dados")
                if(empresasChoose !== 'Selecione uma empresa' && empresasChoose !== 'Nenhuma'){
                 
                  var admin;
                  var master;
                  if(usuariotypeSelect === 0){
                    admin = 1;
                    master = 0;
                  }
                  else if(usuariotypeSelect === 1){
                    admin = 0;
                    master = 1;
                  }
                  else if(usuariotypeSelect == null){
                    admin = updateUser.is_admin;
                    master = updateUser.is_master;
                  }
                  else{
                    admin = 0;
                    master = 0;
                  }
                  for(var x = 0; x<empresasComID.length;x++){
                    if(empresasComID[x].name === empresasChoose){
                      await api.post(`/admin/update-user?id=${userID}`,{
                        name:updateUser.nome,
                        email:updateUser.email,
                        is_admin:admin,
                        phone:updateUser.celular,
                        is_master:master,
                        cpf:updateUser.cpf,
                        corp_id:empresasComID[x].id
                      })
                      toast.success("Dados Salvos")
                      setBool(!bool)
                      handleCloseTelaEdit()
                    }
                  }
                }
                else{
                  var admin;
                  var master;
                  if(usuariotypeSelect === 0){
                    admin = 1;
                    master = 0;
                  }
                  else if(usuariotypeSelect === 1){
                    admin = 0;
                    master = 1;
                  }
                  else if(usuariotypeSelect == null){
                    admin = updateUser.is_admin;
                    master = updateUser.is_master;
                  }
                  else{
                    admin = 0;
                    master = 0;
                  }
                  await api.post(`/admin/update-user?id=${userID}`,{
                    name:updateUser.nome,
                    email:updateUser.email,
                    phone:updateUser.celular,
                    is_admin:admin,
                    is_master:master,
                    cpf:updateUser.cpf,
                    corp_id:updateUser.corp_id
                  })
                  toast.success("Dados Salvos")
                  setBool(!bool)
                  handleCloseTelaEdit()
                }
                  }catch(err){
                    console.log(err)
                    toast.error("Houve um erro ao salvar os dados")
                  }
            }catch(error){
              console.log(error)
            }
          }
        }
      }
      else{
        try{
          toast.info("Atualizando Dados")
          if(empresasChoose !== 'Selecione uma empresa' && empresasChoose !== 'Nenhuma'){
            var admin;
                  var master;
                  if(usuariotypeSelect === 0){
                    admin = 1;
                    master = 0;
                  }
                  else if(usuariotypeSelect === 1){
                    admin = 0;
                    master = 1;
                  }
                  else if(usuariotypeSelect == null){
                    admin = updateUser.is_admin;
                    master = updateUser.is_master;
                  }
                  else{
                    admin = 0;
                    master = 0;
                  }
            for(var y = 0; y<empresasComID.length;y++){
              if(empresasComID[y].name === empresasChoose){
                await api.post(`/admin/update-user?id=${userID}`,{
                  name:updateUser.nome,
                  email:updateUser.email,
                  is_admin:admin,
                  is_master:master,
                  phone:updateUser.celular,
                  cpf:updateUser.cpf,
                  corp_id:empresasComID[y].id
                })
                toast.success("Dados Salvos")
                setBool(!bool)
                handleCloseTelaEdit()
              }
            }
          }
          else{
                  var admin;
                  var master;
                  if(usuariotypeSelect === 0){
                    admin = 1;
                    master = 0;
                  }
                  else if(usuariotypeSelect === 1){
                    admin = 0;
                    master = 1;
                  }
                  else if(usuariotypeSelect == null){
                    admin = updateUser.is_admin;
                    master = updateUser.is_master;
                  }
                  else{
                    admin = 0;
                    master = 0;
                  }
            await api.post(`/admin/update-user?id=${userID}`,{
              name:updateUser.nome,
              email:updateUser.email,
              is_admin:admin,
              is_master:master,
              phone:updateUser.celular,
              cpf:updateUser.cpf,
              corp_id:updateUser.corp_id
            })
            toast.success("Dados Salvos")
            setBool(!bool)
            handleCloseTelaEdit()
          }
            }catch(err){
              console.log(err)
              toast.error("Houve um erro ao salvar os dados")
            }
      }
    }
    const [dropdownSelect, setDropdownSelect] = React.useState(null)
    const [usuariotypeSelect, setUsuariotype] = React.useState(null)
    const usertype = ['Admin','Master','Comum'];
    const handleDropdown = event => {
      setDropdownSelect(event.target.value);
      for(var i = 0; i<empresas.length;i++){
        if(event.target.value === i){
          setEmpresasChoose(empresas[i])
        }
      }
    };
    const handleUsuariotypeSelect = event => {
      setUsuariotype(event.target.value);
    }

  

    return(
        <div>
        <ToastContainer />
            <ReactTables
            size={10}
            funcaoRedirect={funcaoRedirect}
            delet={delet}
            funcaoOpenTelaEdit={handleOpenTelaEdit}
            openTelaEdit={openTelaEdit}
            closeTelaEdit={handleCloseTelaEdit}
            funcaoOpenTelaCad={handleOpenTelaCad}
            openTelaCad={openTelaCad}
            closeTelaCad={handleCloseTelaCad}
            nome='Usuarios Cadastrados'
            data={dados}
            telaCad={
              <div className={classes2.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <form>
                  <Card login>
                    <div style={{height:"620px", width:"480px"}}>
                    <CardBody style={{display:'flex', alignItems:'block', justifyContent:'space-between'}}>
                      <div style={{width:"370"}}>
                      <CustomInput
                        labelText="Nome(Obrigatório)"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange:(
                            salvarNomeUsuario
                          )
                        }
                      }
                      />
                      <CustomInput
                        labelText="Email(Obrigatório)"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange:(
                            salvarEmailUsuario
                          )
                        }
                      }
                      />
                      <CustomInput
                        labelText="Senha(Obrigatório)"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange:(
                            salvarPasswordUsuario
                          ),
                          type: "password",
                        }
                      }
                      />
                      <CustomInput
                        labelText="CPF"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange:(
                            salvarCpfUsuario
                          ),
                          value:(
                            novoUsario.cpf
                          )
                        }}
                      />
                      <CustomInput
                        labelText="Celular"
                        id="phone"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange:(
                            salvarCelularUsuario
                          ),
                          value:(
                            novoUsario.celular
                          )
                        }
                      }
                      />
                      <div style={{display:"flex", alignItems:"row", justifyContent:"center"}}>
                          <GridContainer>
                              <GridItem xs={12} sm={6} md={5} lg={12}>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                  Selecione um tipo de usuário
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={usuariotypeSelect}
                                  onChange={handleUsuariotypeSelect}
                                  inputProps={{
                                    name: "usuariotypeSelect",
                                    id: "usuariotype-select"
                                  }}
                                >
                                {usertype.map((usuario,key)=>
                                  <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={key}
                                  >
                                  {usuario}
                                </MenuItem>
                                )}
                                </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                          </div>
                          <div style={{display:"flex", alignItems:"row", justifyContent:"center"}}>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={5} lg={12}>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                  Selecione uma Empresa
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={dropdownSelect}
                                  onChange={handleDropdown}
                                  inputProps={{
                                    name: "dropdownSelect",
                                    id: "dropdown-select"
                                  }}
                                >
                                {empresas.map((empresa,key)=>
                                  <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={key}
                                  >
                                  {empresa}
                                </MenuItem>
                                )}
                                </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                          </div>
                      <div>
                        <Button color="primary" simple size="lg" block onClick={()=>submeter()} >
                          Salvar
                        </Button>
                      </div>
                      </div>
                    </CardBody>
                  </div>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
            }
            telaEdit={
              <div className={classes2.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                  <form>
                    <Card login>
                    <div style={{height:"100%",width:"100%"}}>
                      <CardBody style={{display:'flex', justifyContent:'center',flexDirection:"column"}}>
                        <div style={{display:'flex',flexDirection:"row",width:"100%"}}>
                          <div style={{marginRight:5}}>
                          <CustomInput
                          labelText="Nome"
                          id="email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange:(
                              salvarEditNomeUser
                            ),
                            defaultValue:(
                              updateUser.nome
                            )
                          }
                          }
                          />
                          <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange:(
                                salvarEditEmailUser
                              ),
                              defaultValue:(
                                updateUser.email
                              )
                            }
                          }
                          />

                          <CustomInput
                            labelText="CPF"
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange:(
                                salvarEditCpfUser
                              ),
                              defaultValue:(
                                updateUser.cpf
                              ),
                              value:(
                                updateUser.cpf
                              )
                            }
                          }
                          />
                          </div>
                          <div style={{marginLeft:5}}>
                          <CustomInput
                            labelText="Celular"
                            id="email"
                            class='teste'
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange:(
                                salvarEditCelularUser
                              ),
                              defaultValue:(
                                updateUser.celular
                              ),
                              value:(
                                updateUser.celular
                              )
                            }
                          }
                          />
                          <CustomInput
                            labelText="Nova senha"
                            id="password"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange:(
                                salvarNovaSenha
                              ),
                              type: "password",
                              }}
                          />
                          <CustomInput
                            labelText="Confirmar senha"
                            id="password"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange:(
                                SalvarConfirmarNovaSenha
                              ),
                              type: "password",
                              }}
                              
                          />
                          </div>
                        </div>
                        <div>
                          <div style={{display:"flex", alignItems:"row", justifyContent:"center",marginTop:10}}>
                          <GridContainer style={{marginRight:5}}>
                              <GridItem xs={12} sm={12} md={10} lg={12}>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                  Selecione um tipo de usuário
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={usuariotypeSelect}
                                  onChange={handleUsuariotypeSelect}
                                  inputProps={{
                                    name: "usuariotypeSelect",
                                    id: "usuariotype-select"
                                  }}
                                >
                                {usertype.map((usuario,key)=>
                                  <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={key}
                                  >
                                  {usuario}
                                </MenuItem>
                                )}
                                </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                            <GridContainer style={{marginLeft:5}}>
                              <GridItem xs={12} sm={10} md={8} lg={12}>
                                <FormControl fullWidth className={classes.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                  Selecione uma Empresa
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={dropdownSelect}
                                  onChange={handleDropdown}
                                  inputProps={{
                                    name: "dropdownSelect",
                                    id: "dropdown-select"
                                  }}
                                >
                                {empresas.map((empresa,key)=>
                                  <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={key}
                                  >
                                  {empresa}
                                </MenuItem>
                                )}
                                </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                          </div>
                          <div style={{marginTop:"4%", marginRight:30}}>
                            <Button color="primary" simple size="lg" block onClick={()=> alterar()}>
                              Salvar
                            </Button>
                          </div>
                        </div>
                        
                      </CardBody>
                    </div>
                    </Card>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          }
            columns={[
                {
                  Header: "Nome",
                  accessor: "name"
                },
                {
                    Header: "Admin",
                    accessor: "admin"
                },
                {
                    Header: "Master",
                    accessor: "master"
                },
                {
                  Header: "Celular",
                  accessor: "celular"
                },
                {
                  Header: "CPF",
                  accessor: "cpf"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                },
                {
                  Header: "Planilhas",
                  accessor: "planilhas",
                  sortable: false,
                  filterable: false
                }
              ]}
            />
        </div>
    );
}
                          