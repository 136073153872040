import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';

const PurpleCheckbox = withStyles({
    root: {
      color: purple[600],
      '&$checked': {
        color: purple[800],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

export default function MenuRestrict(props){
    const [check,setCheck] = React.useState(props.restrict)

    function handleClick(){
        setCheck(!check)
        props.handleChecks(props.id,props.parent_id,!check)
    }

    return(
        <FormControlLabel
        label={props.nome}
        control={<PurpleCheckbox
                checked={check}
                onChange={handleClick}
                name={props.nome}
                color="primary"
                />}
        />
    )
}