import IframeMenus from "views/Dashboard/IframeMenus";
import Dashboard from "views/Dashboard/Dashboard.js";
import Workday from "views/Dashboard/Workday.js";
import Empresas from "views/Dashboard/Empresas.js";
import Menus from "views/Dashboard/Menus.js";
import Usuarios from "views/Dashboard/Usuarios";
import MenuAdmin from "views/Dashboard/MenuAdmin";
import UpdatePassword from "views/Dashboard/UpdatePassword"
import MachineLearning from "views/Dashboard/MachineLearning";
import HistoricoMachineLearning from "views/Dashboard/HistoryMl";
import EditUser from "views/Dashboard/EditUser";
import RedirectUser from "views/Dashboard/redirectUser"
import LoginPage from "views/Pages/LoginPage.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";

  function verificar(){
    if(localStorage.getItem('vetor') == null){
      localStorage.setItem('vetor', JSON.stringify([{
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: 'LoginPage',
        layout: "/auth",
        subAbas: [{
                  path: "/workday",
                  name:'empty',
                  icon: 'DashboardIcon',
                  component: 'Dashboard',
                  layout: "/admin",
                  subAbas: [{
                    path: "/workday",
                    name:'empty',
                    icon: 'DashboardIcon',
                    component: 'IframeMenus',
                    layout: "/admin"
                  }]
                  },]
      }]))
      localStorage.setItem('bool', 'true')
    }
  const vetorResultado = JSON.parse(localStorage.getItem('vetor'));
  
  for(var count = 0; count< vetorResultado.length;count++){
    if(vetorResultado[count].component === 'LoginPage'){
      vetorResultado[count].component = LoginPage
    }
    if(vetorResultado[count].component === 'Menu'){
      vetorResultado[count].component = MenuAdmin
    }
    if(vetorResultado[count].component === 'Restringir Menus'){
      vetorResultado[count].component = EditUser
    }
    if(vetorResultado[count].component === 'Cadastro de Empresas'){
      vetorResultado[count].component = Empresas
    }
    if(vetorResultado[count].component === 'Cadastro de Menus'){
      vetorResultado[count].component = Menus
    }
    if(vetorResultado[count].component === 'Redirect User'){
      vetorResultado[count].component = RedirectUser
    }
    if(vetorResultado[count].component === 'Cadastro de Usuarios'){
      vetorResultado[count].component = Usuarios
    }
    if(vetorResultado[count].component === 'menuMachineLearning'){
      vetorResultado[count].component = MachineLearning
    }
    if(vetorResultado[count].component === 'updatePassword'){
      vetorResultado[count].component = UpdatePassword
    }
    if(vetorResultado[count].component === 'menuHistoricoMachineLearning'){
      vetorResultado[count].component = HistoricoMachineLearning
    }
    if(vetorResultado[count].component === 'Workday'){
      vetorResultado[count].component = Workday
    }
    if(vetorResultado[count].component === 'Dashboard'){
      vetorResultado[count].component = Dashboard
    }
    if(vetorResultado[count].component === 'IframeMenus'){
      vetorResultado[count].component = IframeMenus
    }
    if(vetorResultado[count].icon === 'Apps'){
      vetorResultado[count].icon = Apps
    }
    if(vetorResultado[count].icon === 'Timeline'){
      vetorResultado[count].icon = Timeline
    }
    if(vetorResultado[count].icon === 'DashboardIcon'){
      vetorResultado[count].icon = DashboardIcon
    }
    if(vetorResultado[count].subAbas.length>0){
      for(var contador = 0; contador<vetorResultado[count].subAbas.length; contador++){
        if(vetorResultado[count].subAbas[contador].component === 'LoginPage'){
          vetorResultado[count].subAbas[contador].component = LoginPage
        }
        if(vetorResultado[count].subAbas[contador].component === 'Cadastro de Empresas'){
          vetorResultado[count].subAbas[contador].component = Empresas
        }
        if(vetorResultado[count].subAbas[contador].component === 'Cadastro de Menus'){
          vetorResultado[count].subAbas[contador].component = Menus
        }
        if(vetorResultado[count].subAbas[contador].component === 'Cadastro de Usuarios'){
          vetorResultado[count].subAbas[contador].component = Usuarios
        }
        if(vetorResultado[count].subAbas[contador].component === 'Workday'){
          vetorResultado[count].subAbas[contador].component = Workday
        }
        if(vetorResultado[count].subAbas[contador].component === 'Redirect User'){
          vetorResultado[count].subAbas[contador].component = RedirectUser
        }
        if(vetorResultado[count].subAbas[contador].component === 'Dashboard'){
          vetorResultado[count].subAbas[contador].component = Dashboard
        }
        if(vetorResultado[count].subAbas[contador].component === 'IframeMenus'){
          vetorResultado[count].subAbas[contador].component = IframeMenus
        }
        if(vetorResultado[count].subAbas[contador].icon === 'Apps'){
          vetorResultado[count].subAbas[contador].icon = ''
        }
        if(vetorResultado[count].subAbas[contador].icon === 'Timeline'){
          vetorResultado[count].subAbas[contador].icon = ''
        }
        if(vetorResultado[count].subAbas[contador].icon === 'DashboardIcon'){
            vetorResultado[count].subAbas[contador].icon = ''
          
        }
        if(vetorResultado[count].subAbas[contador].subAbas.length>0){
          for(var t = 0;t<vetorResultado[count].subAbas[contador].subAbas.length;t++){
            if(vetorResultado[count].subAbas[contador].subAbas[t].component === 'IframeMenus'){
              vetorResultado[count].subAbas[contador].subAbas[t].component = IframeMenus
            }
            if(vetorResultado[count].subAbas[contador].subAbas[t].icon === 'Apps'){
              vetorResultado[count].subAbas[contador].subAbas[t].icon = ''
            }
            if(vetorResultado[count].subAbas[contador].subAbas[t].icon === 'Timeline'){
              vetorResultado[count].subAbas[contador].subAbas[t].icon = ''
            }
            if(vetorResultado[count].subAbas[contador].subAbas[t].icon === 'DashboardIcon'){
              vetorResultado[count].subAbas[contador].subAbas[t].icon = ''
            }
          }
        }
      }
    }
  }
  
  return vetorResultado
}
   
export default verificar();


