import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import api from "../../services/api.js"
import { login } from "../../services/auth.js"
import PermIdentity from "@material-ui/icons/PermIdentity";

export default function UpdatePassword() {

  const [senha, setSenha] = React.useState('')
  const [confirmarSenha, setConfimarSenha] = React.useState('')
  const [handleClick, setHandleClick] = React.useState(false)

  async function update() {
    if (senha.length < 8) {
      toast.error('A senha precisa ser maior que 8 caracteres')
    }
    else if (senha !== confirmarSenha) {
      toast.error('Senhas não coincidem')
    }
    else {
      if (handleClick) {
        toast.info('Aguarde um momento')
      }
      try {
        setHandleClick(true)
        const response = await api.post(`/account/update-password-with-token`, {
          newPassword: senha
        })
        login(response.data.token)
        console.log(response)
        toast.success('Senha Atualizada')
        setConfimarSenha('')
        setSenha('')
        setHandleClick(false)
        setTimeout(() => window.location.reload(), 1500);
      } catch (err) {
        setHandleClick(false)
        console.log(err)
        toast.error('Ocorreu um erro ao atualizar a senha')
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <GridContainer justify="center">
        <GridItem xs={8} sm={6} md={12}>
          <form>
            <Card login>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <PermIdentity />
                </CardIcon>
                <h4 style={{ color: "purple", fontWeight: "400" }}>
                  Alterar Senha
                </h4>
              </CardHeader>
              <div style={{ height: "180px", marginTop: 30 }}>
                <CardBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ width: "40%", marginLeft: "5%", marginRight: "5%" }}>
                    <CustomInput
                      labelText="Nova Senha"
                      id="senha"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (
                          (event) => setSenha(event.target.value)
                        ),
                        type: "password",
                      }
                      }
                    />
                  </div>
                  <div style={{ width: "40%", marginLeft: "5%", marginRight: "5%" }}>
                    <CustomInput
                      labelText="Confirmar Nova Senha"
                      id="confirmarSenha"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (
                          (event) => setConfimarSenha(event.target.value)
                        ),
                        type: "password",
                      }
                      }
                    />
                  </div>
                </CardBody>
                <div style={{ float: "right", marginRight: 40 }}>
                  <Button style={{ backgroundColor: "purple", height: "50px" }} simple size="lg" block onClick={() => update()}>
                    Salvar
                </Button>
                </div>
              </div>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </>
  );
}