import React from "react";

// @material-ui/core components
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import api from "services/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function IframeMenus(props) {
  const [url, setUrl] = React.useState('')
  
  async function receberDados(){
    let corp_id;
    try{
      const userLogged = await api.get("/account/identify")
      const usuario = userLogged.data
      corp_id = usuario.corp_id
    }catch(error){
      console.log(error)
    }
    try{
      const response = await api.get(`/admin/corp-bis?corp_id=${corp_id}`)
      const dados = response.data.bis
      for(var i = 0; i<dados.length; i++){
        if(dados[i].parent_id != null){
          for(var u =0; u<dados.length; u++){
            if(dados[i].parent_id === dados[u].id){
              if(dados[u].parent_id == null){
                if(`/admin/${dados[u].name}/${dados[i].name}` === props.location.pathname){
                  if(dados[i].sub_menus.length===0){
                    if(dados[i].bi!== undefined || dados[i].bi!= null){
                      const aspas = /"/
                      if(dados[i].bi.url.includes('https://') === false){
                        toast.error('Sua url está incorreta')
                      }
                      else if(aspas.test(dados[i].bi.url)){
                        toast.error('Sua url está incorreta')
                      }
                      else{
                        setUrl(dados[i].bi.url)
                        
                      }
                    }
                  }
                }
              }
              else{
                for(var t =0; t<dados.length; t++){
                  if(dados[u].parent_id === dados[t].id){
                    if(`/admin/${dados[t].name}/${dados[u].name}/${dados[i].name}` === props.location.pathname){
                        if(dados[i].bi!== undefined || dados[i].bi!= null){
                          const aspas = /"/
                          if(dados[i].bi.url.includes('https://') === false){
                            toast.error('Sua url está incorreta')
                          }
                          else if(aspas.test(dados[i].bi.url)){
                            toast.error('Sua url está incorreta')
                          }
                          else{
                            setUrl(dados[i].bi.url)
                          }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      
    }catch(err){
      console.log(err)
      toast.error("Ocorreu um erro ao carregar a BI")
    }
  }

    React.useEffect(()=>{
      receberDados();
    })

  return (
      <GridContainer>
        <GridItem xs={12}>
          {url === '' ? 
            <ToastContainer/>
          :
            <iframe width="100%" style={{height: "1000px"}} height="1000px" src={url} frameBorder="0" allowFullScreen={true}></iframe>
          }       
        </GridItem>
      </GridContainer>
  );
}       
