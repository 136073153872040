import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/AddCircle";
import Edit from "@material-ui/icons/Edit";
import Search from "@material-ui/icons/Search";
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import Modal from '@material-ui/core/Modal';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import {Link} from 'react-router-dom'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ModalStyleBI:{
    width:"60%",
    height:"480px",
    marginLeft:"20%",
    marginRight:"20%",
    marginTop:"5%",
  },
  ModalStyleEdit:{
    width:"60%",
    height:"500px",
    marginLeft:"20%",
    marginRight:"20%",
    marginTop:"1%",
  },
  ModalStyleEditMenu:{
    width:"430px",
    height:"400px",
    marginLeft:"36%",
    marginTop:"5%",
  },
  ModalStyleTelaConfirm:{
    marginLeft:"36%",
    marginTop:"10%"
  },
  ModalStyleTelaErrorConfirm:{
    marginLeft:"20%",
    marginTop:"10%",
    marginRight:"20%"
  },
  ModalStyleCad:{
    width:"600px",
    height:"660px",
    marginLeft:"30%",
    marginRight:"30%",
    marginTop:"1%",
  }
};

const useStyles = makeStyles(styles);
export default function ReactTables(props) {
  const classes = useStyles();
  return (
    <div>
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
              <h4 className={classes.cardIconTitle}>{props.nome}</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={
                props.nome === 'Redirect User'?
                (props.data.map((prop,key)=>{
                  return {
                    NomeArquivo:prop.nomeArquivo,
                    observacao:prop.observacao,
                    DataImportacao:prop.data,
                    actions: prop.url === true ?
                    <div className="actions-right">  
                          <Button
                            justIcon
                            round
                            simple
                            onClick={
                              ()=>props.func(key)
                            }
                            color="info"
                            size='lg'
                          >
                            <GetAppIcon />
                          </Button>{" "}
                          <Button
                          justIcon
                          round
                          simple
                          onClick={
                            ()=>props.funcaoOpenTelaConfirm(key,prop.IDLogArquivoImportado)
                          }
                          color="danger"
                          className="remove"
                          size='lg'
                          >
                          <Close />
                        </Button>{" "}
                          </div>
                          :
                          <div className="actions-right">  
                          <Button
                          justIcon
                          round
                          simple
                          onClick={
                            ()=>props.funcaoOpenTelaConfirm(key,prop.IDLogArquivoImportado)
                          }
                          color="danger"
                          className="remove"
                          size='lg'
                          >
                          <Close />
                        </Button>
                        </div>
                  }
                }))
                :
                props.nome === "Menu Admin" ?
                (props.data.map((prop,key)=>{
                  return {
                    NomeArquivo:prop.nomeArquivo,
                    observacao:prop.observacao,
                    DataImportacao:prop.data,
                    TipoDePlanilha:prop.TipoDePlanilha,
                    actions: prop.url === true ?
                    <div className="actions-right">  
                          <Button
                            justIcon
                            round
                            simple
                            onClick={
                              ()=>props.func(key)
                            }
                            color="info"
                            size='lg'
                          >
                            <GetAppIcon />
                          </Button>{" "}
                          <Button
                          justIcon
                          round
                          simple
                          onClick={
                            ()=>props.funcaoOpenTelaConfirm(key,prop.IDLogArquivoImportado)
                          }
                          color="danger"
                          className="remove"
                          size='lg'
                          >
                          <Close />
                        </Button>{" "}
                          </div>
                          :
                          <div className="actions-right"> 
                          <Button
                          justIcon
                          round
                          simple
                          onClick={
                            ()=>props.funcaoOpenTelaConfirm(key,prop.IDLogArquivoImportado)
                          }
                          color="danger"
                          className="remove"
                          size='lg'
                          >
                          <Close />
                        </Button>
                        </div>
                  }
                }))
                :
                props.nome === "Menus Cadastrados" ?
                props.data.map((prop,key) => {
                  return {
                    position: prop.pos,
                    name: prop.name,
                    actions: (
                      prop.name !=="Adicionar Novo" ?
                      ((prop.nivel3 !== true) ?
                        <div className="actions-right">          
                          <Button
                            justIcon
                            round
                            simple
                            onClick={
                              ()=>props.func(prop.id)
                            }
                            color="info"
                            size='lg'
                          >
                            <Search />
                          </Button>{" "}
                          <Button
                            justIcon
                            round
                            simple
                            onClick={()=>props.funcaoOpenTelaEditMenu(prop.id,prop.name)}
                            color="info"
                            size='lg'
                          >
                            <Edit />
                          </Button>{" "}
                          <Button
                            justIcon
                            round
                            simple
                            onClick={
                              ()=>props.delet(prop.id)
                            }
                            color="danger"
                            className="remove"
                            size='lg'
                          >
                            <Close />
                          </Button>{" "}
                        </div>
                        :
                        <div className="actions-right">
                          <Button
                            justIcon
                            round
                            simple
                            onClick={()=>props.funcaoOpenTelaEditMenu(prop.id,prop.name)}
                            color="info"
                            size='lg'
                          >
                            <Edit />
                          </Button>{" "}
                          <Button
                            justIcon
                            round
                            simple
                            onClick={
                              ()=>props.delet(prop.id)
                            }
                            color="danger"
                            className="remove"
                            size='lg'
                          >
                            <Close />
                          </Button>{" "}
                        </div>)
                        
                      : 
                      <div className="actions-right">
                        <Add
                        style={{color:'green', cursor:'pointer', fontSize:30, marginRight:15}}
                        onClick={()=>props.funcaoOpenTelaCad()} 
                        />
                      </div>
                    )
                  };
                })
                :
                (
                  props.nome === "Empresas Cadastradas" ? 
                    props.data.map((prop)=>{
                    return {
                      position: prop.pos,
                      name: prop.name,
                      cnpj: prop.cnpj,
                      address: prop.address,
                      admin: prop.admin_id,
                      id: prop.id,
                      actions: (
                        prop.name !== "Adicionar Novo" ?
                        // we've added some custom button actions
                        <div className="actions-right">          
                          <Button
                            justIcon
                            round
                            simple
                            onClick={()=>props.funcaoOpenTelaEditBI(prop.id)}
                            color="info"
                            size='lg'
                          >
                            <Search />
                          </Button>{" "}
                          <Button
                            justIcon
                            round
                            simple
                            onClick={()=>props.funcaoOpenTelaEdit(prop.id)}
                            color="info"
                            size='lg'
                          >
                            <Edit />
                          </Button>{" "}
                          <Button
                            justIcon
                            round
                            simple
                            onClick={()=>props.func(prop.id)}
                            color="danger"
                            className="remove"
                            size='lg'
                          >
                            <Close />
                          </Button>{" "}
                        </div>
                        : 
                        <div className="actions-right">
                          <Add
                          style={{color:'green', cursor:'pointer', fontSize:30, marginRight:15}}
                          onClick={()=>props.funcaoOpenTelaCad()} 
                          />
                        </div>
                      )
                    };
                  })
                :
                props.nome === "Usuarios Cadastrados" ?
                props.data.map((prop,key)=>{
                  return {
                    id: prop.id,
                    name: prop.name,
                    admin: prop.admin,
                    master: prop.master,
                    celular: prop.celular,
                    cpf: prop.cpf,
                    planilhas: (prop.admin === "Sim" ?
                    <div className="actions-right"> 
                    <Button
                          justIcon
                          round
                          simple
                          onClick={()=>{props.funcaoRedirect(prop.id)}}
                          color="info"
                          size='lg'
                        >
                          <DescriptionIcon                       
                            />
                        </Button>{" "}  
                    </div>
                    :
                    <div className="actions-right"> 
                          
                    </div>),
                    actions: (
                      prop.name !== "Adicionar Novo" ?
                      // we've added some custom button actions
                      <div className="actions-right"> 
                            
                        <Button
                            justIcon
                            round
                            simple
                            onClick={()=>props.funcaoOpenTelaEdit(prop.id)}
                            color="info"
                            size='lg'
                          >
                            <Edit />
                          </Button>{" "}
                          
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            props.delet(prop.id)
                          }}
                          color="danger"
                          className="remove"
                          size='lg'
                        >
                          <Close />
                        </Button>{" "}
                      </div>
                      : 
                      <div className="actions-right">
                        <Add
                        style={{color:'green', cursor:'pointer', fontSize:30, marginRight:15}}
                        onClick={()=>props.funcaoOpenTelaCad()}
                        />
                      </div>
                    )
                  };
                })
                :
                props.nome === 'Usuarios Associados'?
                props.data.map((prop,key) => {
                  return{
                    name:prop.name,
                    email:prop.email,
                    cpf:prop.cpf,
                    actions:(
                      <div className="actions-right">
                        <Link to={{pathname:`/admin/restringirMenus/${prop.id}`,state:prop}}>
                          <Button
                              justIcon
                              round
                              simple
                              color="info"
                              size='lg'
                            >
                              <Edit />
                          </Button>
                        </Link>
                      </div>
                        //onClick={()=>window.location=`/admin/editUser?id=${prop.id}`}
                    )
                  }})
                
                :
                props.nome == 'Histórico Machine Learning' ?
                props.data.map((prop,key)=> {
                  return{
                    resposta: prop.response,
                    data: prop.date,
                    TipoDeModelo:prop.model_id!==undefined?prop.model_id.name:prop.model_id
                  }
                })
                :
                props.nome == 'Histórico Machine Learning master' ?
                props.data.map((prop,key)=> {
                  return{
                    empresa: prop.name,
                    actions:(
                      <div className="actions-right">  
                      <Link to={{pathname:`/admin/menuHistoricoMachineLearning/${prop.id}`,state:prop}}>  
                        <Button
                        justIcon
                        round
                        simple
                        color="info"
                        size='lg'
                      >
                        <Search />
                      </Button>{" "}
                      </Link>
                    </div>
                    )
                  }
                })
                :

                props.data.map((prop,key) => {
                  return {
                    name: prop.name,
                    actions: (
                      prop.name !== "Adicionar Novo" ?
                      // we've added some custom button actions
                      <div className="actions-right">          
                        <Button
                          justIcon
                          round
                          simple
                          color="info"
                          size='lg'
                        >
                          <Search />
                        </Button>{" "}
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                          }}
                          color="danger"
                          className="remove"
                          size='lg'
                        >
                          <Close />
                        </Button>{" "}
                      </div>
                      : 
                      <div className="actions-right">
                        <Add
                        style={{color:'green', cursor:'pointer', fontSize:30, marginRight:15}}
                        
                        />
                      </div>
                    )
                  };
                })
                )
                
              }
              filterable
              columns={props.columns}
              defaultPageSize={props.size}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    <Modal
      open={props.openTelaCad}
      onClose={props.closeTelaCad}
    >
      <div className={classes.ModalStyleCad}>
        {props.telaCad}
      </div>
    </Modal>
    <Modal
      open={props.openTelaEditBI}
      onClose={props.closeTelaEditBI}
    >
      <div className={classes.ModalStyleBI}>
        {props.telaEditBI}
      </div>
    </Modal>
    <Modal
      open={props.openTelaEdit}
      onClose={props.closeTelaEdit}
    >
      <div className={classes.ModalStyleEdit}>
        {props.telaEdit}
      </div>
    </Modal>
    <Modal
      open={props.openTelaEditMenu}
      onClose={props.closeTelaEditMenu}
    >
      <div className={classes.ModalStyleEditMenu}>
        {props.telaEditMenu}
      </div>
    </Modal>
    <Modal
      open={props.openTelaConfirm}
      onClose={props.closeTelaConfirm}
    >
      <div className={classes.ModalStyleTelaConfirm}>
        {props.TelaConfirm}
      </div>
    </Modal>
    <Modal
      open={props.openTelaErrorConfirm}
      onClose={props.closeTelaErrorConfirm}
    >
      <div className={classes.ModalStyleTelaErrorConfirm}>
        {props.TelaErrorConfirm}
      </div>
    </Modal>
    </div>
  );
}
