import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Card from "components/Card/Card.js";
import './machineLearning.css'
import api from "../../services/api"
import ReactTables from "../Tables/ReactTables"


const styles2 = {
    card: {
        height: "35%",
        display: "flex",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    title: {
        fontWeight: "bold",
        color: "#6B008B",
    },
    button: {
        width: "20%",
        backgroundColor: "#6B008B",
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        padding: 5,
        marginBottom: "1%"
    },
    disabled: {
        width: "20%",
        backgroundColor: "#6B008B",
        borderRadius: 10,
        display: "flex",
        opacity: '0.5',
        justifyContent: "center",
        padding: 5,
        marginBottom: "1%"
    },
}
const useStyles2 = makeStyles(styles2);
const useStyles = makeStyles(styles)

export default function MachineLearning() {
    const classes2 = useStyles2();
    const classes = useStyles()
    const [controle, setControle] = React.useState(0)
    const [data, setData] = React.useState([{resposta:'',date:'',model_id:{name:''}}])
    const [bool, setBool] = React.useState(false)
    const [optionSelected, setOptionSelected] = React.useState('')
    const [options,setOptions] = React.useState([{id:'none', name:'Selecione uma opção',file_name:'none'}])
    const [optionsFilter, setOptionsFilter] = React.useState([])

    const handleOptionSelected = event => {
        setOptionSelected(event.target.value)
    }

    async function carregarDados() {
        try {
            const userInfo = await api.get('/account/identify')
            const corp_id = userInfo.data.corp_id
            const response = await api.get(`/admin/executions-ml?corp_id=${corp_id}`)
            const request = await api.get('/admin/machine-learning-models')
            const aux = request.data.models
            aux.push({id:'none', name:'Selecione uma opção',file_name:'none'})
            setOptions(aux)
            setData(response.data)
            const optionsAux = []
            for(let i=0; i<response.data.length; i++){
                let bool = false
                for(let y = 0; y<optionsAux.length; y++){
                    if(response.data[i].model_id.name ===optionsAux[y]){
                        bool = true
                    }
                }
                if(!bool){
                    optionsAux.push(response.data[i].model_id.name)
                }
            }
            setOptionsFilter(optionsAux)
            console.log(response.data)
        } catch (err) {
            console.log(err)
        }
    }

    React.useEffect(() => {
        carregarDados()
    }, [bool])

    const customFilter = ({ filter, onChange }) => {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          > 
            <option value="all">Exibir Todos</option>
            {optionsFilter
              .filter((item, i, s) => s.lastIndexOf(item) == i)
              .map(function (value) {
                if(value === data[data.length-1]){
                  return null
                }
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
          </select>
        );
      };

    async function executar() {
        setControle(1)
        if (controle == 1) {
            toast.info("Aguarde a execução finalizar")
        }
        else {
            toast.info("executando")
            const file_name = options[optionSelected].file_name
            try {
                const response = await api.post(`/admin/machine-learning-py?file_name=${file_name}`)
                console.log(response)
                toast.success("Executado com sucesso")
                setControle(0)
                setBool(!bool)
            } catch (err) {
                console.log(err)
                toast.error("Houve um erro na execução")
                setControle(0)
            }
        }
    }
    return (
        <>
            <Card className={classes2.card}>
                <ToastContainer />
                <div className={classes2.container}>
                    <h1 className={classes2.title}>Executar machine Learning</h1>
                    <GridContainer style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "3%" }}>
                        <GridItem xs={12} sm={5} md={6} lg={4}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                    {options[options.length - 1].name}
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={optionSelected}
                                    onChange={handleOptionSelected}
                                    inputProps={{
                                        name: "optionSelect",
                                        id: "option-select"
                                    }}
                                >
                                    {options.map((item, key) =>
                                        key === options.length - 1 ?
                                            null
                                            :
                                            <MenuItem
                                                key={key}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected
                                                }}
                                                value={key}
                                            >
                                                {item.name}
                                            </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                    <div id={optionSelected === '' ? '' : 'button'} className={optionSelected === '' ? classes2.disabled : classes2.button} onClick={() => optionSelected === '' ? () => { return false } : executar()}>
                        <p style={{ color: "white", fontWeight: "bold", marginTop: "2%", fontSize: 16 }}>Executar</p>
                    </div>
                </div>
            </Card>
            <ReactTables
                data={data}
                columns={[
                    {
                        Header: "Data",
                        accessor: "data",
                        width:150
                    },{
                        Header: "Tipo de modelo",
                        accessor: "TipoDeModelo",
                        width:150,
                        sortable: false,
                        filterMethod: (filter, row) => {
                          if(filter.value === 'all'){
                            return true;
                          }
                          return row[filter.id] === filter.value;
                        },
                        Filter: ({ filter, onChange }) =>
                          customFilter({filter, onChange })
                    },
                    {
                        Header: "Resposta",
                        accessor: "resposta",
                    },
                    {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 1
                    }
                ]}
                nome="Histórico Machine Learning"
            />
        </>
    )
}
