import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './MenuAdmin.css';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import ReactTables from "../Tables/ReactTables"
import api from "../../services/api.js"
import DropzoneComponent from "react-dropzone-component";
import Loading from "../Pages/Loading"
import "react-dropzone-component/styles/filepicker.css";
import "dropzone/dist/dropzone.css";

const styles1 = {
    container: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "400px",
        height: "100px",
        borderRadius: 20
    },
    containerError: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 20
    }
}
const useStyles = makeStyles(styles)
const useStyles1 = makeStyles(styles1);
export default function App () {
    const [loading, setLoading] = React.useState(false);
    const [errorUpload, setErrorUpload] = React.useState('');
    const [file1, setFile1] = React.useState("");
    const [bool, setBool] = React.useState(false)
    const [handleClick, setHandleClick] = React.useState(false)
    const classes = useStyles();
    const classes1 = useStyles1();


    async function onClickHandler () {
        setHandleClick(true)
        if (handleClick) {
            return 0;
        }
        const config = {
            onUploadProgress: progressEvent => setLoading(true)
        }
        const data = new FormData()
        let rota;
        switch (menuSelect) {
            case 0:
                rota = '/absenteismo'
                break;

            case 1:
                rota = '/ficha-financeira'
                break;

            case 2:
                rota = '/inspecao'
                break;

            case 3:
                rota = '/inspecao-matriz-risco'
                break;

            case 4:
                rota = '/provisao'
                break;

            case 5:
                rota = '/tipo-inconsistencia'
                break;

            case 6:
                rota = '/locais'
                break;

            case 7:
                rota = '/jornada-trabalho'
                break;

            case 8:
                rota = "/dre"
                break;

            case 9:
                rota = '/headcount'
                break;

            case 10:
                rota = "/ferias"
                break;

            case 11:
                rota = "sem rota"
                break;

            default:
                rota = '/'
                break;
        }
        data.append(
            'image',
            file1,
        );
        if (!file1) {
            toast.error("Selecione uma planilha")
        }
        else if (rota === '/') {
            toast.error("Selecione um tipo de planilha")
        }
        else {
            toast.info("Enviando planilha")
            await api.post(`/xls${rota}`, formData(file1), config)
                .then((res) => {
                    console.log(res)
                    setLoading(false)
                    toast.success('Upload Completo')
                    setTimeout(() => (window.location.reload(), 20000))
                    setHandleClick(false)
                })
                .catch((err) => {
                    console.log(err.response)
                    setLoading(false)
                    if (err.response !== undefined) {
                        if (err.response.status === 400) {
                            setErrorUpload(JSON.stringify(err.response.data))
                            setTimeout(() => (toast.error("Upload falhou"), 20000))
                            setOpenTelaErrorConfirm(true)
                        }
                        else if (err.response.status === 500) {
                            setTimeout(() => (toast.error("Upload falhou"), 20000))
                            setTimeout(() => (window.location.reload(), 30000))
                        }
                    }
                    else {
                        toast.error("Ocorreu um erro de acesso")
                    }
                    setHandleClick(false)
                })
        }
    }
    const customFilter = ({ filter, onChange }) => {
        return (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option value="all">Exibir Todos</option>
                {menus
                    .filter((item, i, s) => s.lastIndexOf(item) == i)
                    .map(function (value) {
                        if (value === menus[menus.length - 1]) {
                            return null
                        }
                        return (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        );
                    })}
            </select>
        );
    };

    const [menuSelect, setMenuSelect] = React.useState('')
    const [histUpload, setHistUpload] = React.useState([])
    const handleMenuSelect = event => {
        setMenuSelect(event.target.value)
    }
    const menus = ['Absenteismo', 'Ficha Financeira', 'Inspeção', 'Inspeção Matriz', 'Provisao', 'Tipo Inconsistência', 'Locais', 'Jornada de Trabalho', 'Dre', 'Head Count', 'Férias', 'Selecione um tipo de planilha']

    async function getUploads () {
        try {
            const response = await api.get("/xls/uploaded-files")
            console.log(response)
            var vetor = response.data.files
            var vetAux = []
            for (let i = 0; i < vetor.length; i++) {
                let dataF = '';
                let dataA = '';
                let dataA2 = '';
                let dataA3 = '';
                let bool = true
                for (let t = 0; t < 10; t++) {
                    dataA = `${dataA}` + `${vetor[i].DataImportacao[t]}`
                    if (t === 3 && bool === true) {
                        dataA2 = `${dataA2}` + `${dataA}`
                        bool = false
                    }
                    if (t === 5) {
                        dataA3 = `${dataA3}` + `${vetor[i].DataImportacao[t]}`
                    }
                    if (t === 6) {
                        dataA3 = `${dataA3}` + `${vetor[i].DataImportacao[t]}-`
                    }
                    if (t === 8) {
                        dataF = `${dataF}` + `${vetor[i].DataImportacao[t]}`
                    }
                    if (t === 9) {
                        dataF = `${dataF}` + `${vetor[i].DataImportacao[t]}` + `-${dataA3}` + `${dataA2}`
                    }
                }
                vetAux.push({ nomeArquivo: vetor[i].NomeArquivo, observacao: vetor[i].Observacao, data: dataF, url: vetor[i].Url != null ? true : false, urLink: vetor[i].Url, IDLogArquivoImportado: vetor[i].IDLogArquivoImportado, TipoDePlanilha: vetor[i].IDTipoArquivo.Descricao })
            }
            setHistUpload(vetAux)
        } catch (err) {
            console.log(err)
        }
    }
    React.useEffect(() => {
        getUploads();
    }, [bool])
    const componentConfig = {
        showFiletypeIcon: true,
        iconFiletypes: [".xls"],
        postUrl: "no-url"
    };

    function formData (file) {
        var formData = new FormData();
        formData.append("image", file);
        return formData;
    }
    function funcao (key) {
        for (var i = 0; i < histUpload.length; i++) {
            if (i === key) {
                window.location = `${histUpload[i].urLink}`
            }
        }
    }

    //const [key, setKey] = React.useState(false)
    const [idLogArquivoImportado, setIDLogArquivoImportado] = React.useState(false)
    async function del () {
        const idArq = idLogArquivoImportado;
        try {
            await api.delete(`/admin/delete-file?IDLogArquivoImportado=${idArq}`)
            toast.success("Arquivo Deletado")
            setBool(!bool)
        } catch (err) {
            console.log(err)
            toast.error("Erro ao excluir")
        }
        setOpenTelaConfirm(false)
    }

    const [openTelaErrorConfirm, setOpenTelaErrorConfirm] = React.useState(false)
    const [openTelaConfirm, setOpenTelaConfirm] = React.useState(false)
    function handleCloseTelaConfirm () {
        setOpenTelaConfirm(false)
    }
    function handleCloseTelaErrorConfirm () {
        setOpenTelaErrorConfirm(false)
        setTimeout(() => (window.location.reload(), 20000))
    }
    function handleOpenTelaErrorConfirm () {
        setOpenTelaErrorConfirm(true)
    }
    function handleOpenTelaConfirm (key, IDLogArquivoImportado) {
        setOpenTelaConfirm(true)
        //setKey(key)
        setIDLogArquivoImportado(IDLogArquivoImportado)
    }
    var djsConfig1 = {
        autoProcessQueue: false,
        dictDefaultMessage: "Planilha",
        acceptedFiles: ".xlsx"
    }
    var eventFile1 = { addedfile: file => setFile1(file) };
    return (
        <div className="container">
            <ToastContainer />
            { loading ? <Loading /> : ''}
            <div className="row">
                <DropzoneComponent
                    config={componentConfig}
                    eventHandlers={eventFile1}
                    djsConfig={djsConfig1}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <GridContainer style={{ marginLeft: "20%" }}>
                    <GridItem xs={12} sm={6} md={5} lg={6}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                {menus[menus.length - 1]}
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}
                                value={menuSelect}
                                onChange={handleMenuSelect}
                                inputProps={{
                                    name: "menuSelect",
                                    id: "menu-select"
                                }}
                            >
                                {menus.map((menu, key) =>
                                    key === menus.length - 1 ?
                                        null
                                        :
                                        <MenuItem
                                            key={key}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={key}
                                        >
                                            {menu}
                                        </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </GridItem>
                </GridContainer>
                <div style={{ marginRight: "20%", marginTop: "3%" }}><button type="button" class="btn btn-success btn-block" onClick={onClickHandler}>Upload</button></div>
            </div>
            <div>
                <ReactTables
                    nome="Menu Admin"
                    func={funcao}
                    data={histUpload}
                    openTelaConfirm={openTelaConfirm}
                    openTelaErrorConfirm={openTelaErrorConfirm}
                    closeTelaConfirm={handleCloseTelaConfirm}
                    closeTelaErrorConfirm={handleCloseTelaErrorConfirm}
                    funcaoOpenTelaErrorConfirm={handleOpenTelaErrorConfirm}
                    funcaoOpenTelaConfirm={handleOpenTelaConfirm}
                    TelaConfirm={
                        <div className={classes1.container}>
                            <div style={{ marginTop: "2%" }}><p style={{ fontSize: "15px" }}>Têm certeza que deseja excluir?</p></div>
                            <div>
                                <Button color="primary" simple size="lg" onClick={() => { del() }}>Sim</Button>
                                <Button color="primary" simple size="lg" onClick={() => { handleCloseTelaConfirm() }}>Não</Button>
                            </div>
                        </div>
                    }
                    TelaErrorConfirm={
                        <div className={classes1.containerError}>
                            <h4>Os seguintes erros ocorreram ao enviar a planilha:</h4>
                            <div style={{ marginTop: "2%", height: "400px", overflowY: "scroll" }}><p style={{ fontSize: "15px" }}>{errorUpload}</p></div>
                            <div>
                                <Button color="primary" simple size="lg" onClick={() => { handleCloseTelaErrorConfirm() }}>Ok</Button>
                            </div>
                        </div>
                    }
                    columns={[
                        {
                            Header: "Nome do arquivo",
                            accessor: "NomeArquivo"
                        }, {
                            Header: "Tipo de planilha",
                            accessor: "TipoDePlanilha",
                            sortable: false,
                            filterMethod: (filter, row) => {
                                if (filter.value === 'all') {
                                    return true;
                                }
                                return row[filter.id] === filter.value;
                            },
                            Filter: ({ filter, onChange }) =>
                                customFilter({ filter, onChange })
                        },
                        {
                            Header: "Observacao",
                            accessor: "observacao"
                        },
                        {
                            Header: "Data",
                            accessor: "DataImportacao"
                        },
                        {
                            Header: "Action",
                            accessor: "actions",
                            sortable: false,
                            filterable: false
                        }
                    ]}
                />
            </div>
        </div>
    );
}
