import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2
  }
});

function Loading(props) {
  const { classes } = props;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <CircularProgress
        className={classes.progress}
        style={{ color: '#00CCFF' }}
        size={60}
      />
    </Grid>
  );
}

export default withStyles(styles)(Loading);
