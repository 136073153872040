import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { ToastContainer, toast } from 'react-toastify';
import Button from "components/CustomButtons/Button.js";
import ReactTables from "../Tables/ReactTables"
import api from "../../services/api.js"
import 'react-toastify/dist/ReactToastify.css';

const styles1 = {
  container:{
   backgroundColor:"white",
   display:"flex",
   flexDirection:"column",
   alignItems:"center",
   width:"400px",
   height:"100px",
   borderRadius:20
  }
}

const useStyles1 = makeStyles(styles1);

export default function RedirectUser(props) {
  const classes = useStyles1();
  const [bool, setBool] = React.useState(false)
  const [id, setID] = React.useState(null)
  const [dados, setDados] = React.useState([])
  async function receberDados(){
    let str = '';
    for(var i =0; i<window.location.search.length;i++){
      if(i>3){
        str = `${str}` + `${window.location.search[i]}`
      }
    }
    setID(str)
    try{
      const response = await api.get(`/admin/uploaded-files?id=${str}`)
      var vetor = [response.data.files]
      var vetAux = []
      for(let i =0; i<vetor[0].length;i++){
        let dataF = '';
        let dataA = '';
        let dataA2 = '-';
        let dataA3= '';
        let bool = true
        for(let t = 0; t<10; t++){
          dataA=`${dataA}`+`${vetor[0][i].DataImportacao[t]}`
          if(t === 3 && bool === true){
            dataA2 = `${dataA2}`+`${dataA}`
            bool = false
          }
          if(t === 5){
            dataA3 = `${dataA3}`+`${vetor[0][i].DataImportacao[t]}` 
          }
          if(t === 6){
            dataA3 = `${dataA3}`+`${vetor[0][i].DataImportacao[t]}-` 
          }
          if(t === 8){
            dataF = `${dataF}`+`${vetor[0][i].DataImportacao[t]}`
          }
          if(t === 9){
            dataF = `${dataF}`+`${vetor[0][i].DataImportacao[t]}`+`-${dataA3}`+`${dataA2}`
          }
        }
        vetAux.push({nomeArquivo:vetor[0][i].NomeArquivo, observacao:vetor[0][i].Observacao, data:dataF, url:vetor[0][i].Url != null? true : false, urLink:vetor[0][i].Url, IDLogArquivoImportado:vetor[0][i].IDLogArquivoImportado})
      }

      setDados(vetAux)
    }catch(err){
      console.log(err)
    }
  }
  function funcao(key){
    for(var i =0; i<dados.length; i++){
      if(i === key){
        window.location=`${dados[i].urLink}`
      }
    }
  }
  //const [key, setKey] = React.useState(false)
  const [idLogArquivoImportado, setIDLogArquivoImportado] = React.useState(false)

  async function del(){
    const idArq = idLogArquivoImportado;
    try{
      await api.delete(`/admin/delete-file?IDLogArquivoImportado=${idArq}&id=${id}`)
      toast.success("Arquivo Deletado")
      setBool(!bool)
    }catch(err){
      console.log(err)
      toast.error("Erro ao excluir")
    }
    setOpenTelaConfirm(false)
  }

  const [openTelaConfirm, setOpenTelaConfirm] = React.useState(false)
    function handleCloseTelaConfirm(){
      setOpenTelaConfirm(false)
    }
    function handleOpenTelaConfirm (key,IDLogArquivoImportado){
      setOpenTelaConfirm(true)
      //setKey(key)
      setIDLogArquivoImportado(IDLogArquivoImportado)
    }
  React.useEffect(()=>{
    receberDados();
  },[bool])
  
  return (
    <div>
      <ToastContainer/>
      <ReactTables
            nome = 'Redirect User'
            data={dados}
            func={funcao}
            openTelaConfirm={openTelaConfirm}
            closeTelaConfirm={handleCloseTelaConfirm}
            funcaoOpenTelaConfirm={handleOpenTelaConfirm}
            TelaConfirm={
              <div className={classes.container}>
                <div style={{marginTop:"2%"}}><p style={{fontSize:"15px"}}>Têm certeza que deseja excluir?</p></div>
                <div>
                  <Button color="primary" simple size="lg" onClick={()=>{del()}}>Sim</Button>
                  <Button color="primary" simple size="lg" onClick={()=>{handleCloseTelaConfirm()}}>Não</Button>
                </div>
              </div>
            }
            columns={[
              {
                Header: "Nome do arquivo",
                accessor: "NomeArquivo"
              },
              {
                  Header: "Observacao",
                  accessor: "observacao"
              },
              {
                  Header:"Data",
                  accessor:"DataImportacao"
              },
              {
                Header: "Action",
                accessor: "actions",
                sortable: false,
                filterable: false
              }
            ]}
      />
    </div>
  );
}    
