import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import api from "../../services/api"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTables from "../Tables/ReactTables"
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  container:{
    width:400,
    marginLeft:100,
    marginTop:100,
  },
  container:{
    width:"100%",
    height:"100%"
  }
}
const useStyles = makeStyles(styles);


export default function Menus() {
  const classes = useStyles();
  const [dados, setDados] = React.useState([])
  const [nomeAtual, setNomeAtual] = React.useState("Menus Cadastrados")
  const [bool, setBool] = React.useState(false)
  const [urlID, setUrlID] = React.useState('')
  const [novomenu, setNovomenu] = React.useState('');
  const [openTelaEdit, setOpenTelaEdit] = React.useState(false)
  const [menuNome, setMenuNome] = React.useState('')
  const [menuIdChoose, setMenuIdChoose] = React.useState('')
  const [openTelaCad, setOpenTelaCad] = React.useState(false)

  async function receberDados(){
    let str = '';
    let nv2 = false
    for(var ic =0; ic<window.location.search.length;ic++){
      if(ic>3){
        str = `${str}` + `${window.location.search[ic]}`
      }
    }
    str === '' ? setUrlID(null):setUrlID(str)
    
    try{
      const response = await api.get("/admin/menus")
      var vetor = response.data.menus
      var aux = []
      var auxF = []
      var auxFF = []
      for(var contador = 0; contador<vetor.length; contador++){
        if(str === vetor[contador].id){
          nv2=false
          for(var contF = 0; contF<vetor[contador].sub_menus.length;contF++){
            if(contF === 0){
              auxF.push({name:"Adicionar Novo",id:null, parent_id:null,pos:0,sub_menus:[]})
            }
            auxF.push({name:vetor[contador].sub_menus[contF].name,id:vetor[contador].sub_menus[contF].id,parent_id:vetor[contador].sub_menus[contF].parent_id,sub_menus:vetor[contador].sub_menus[contF].sub_menus,pos:contF+1,nivel3:false})
          }
          if(auxF.length === 0){
            auxF.push({name:"Adicionar Novo", parent_id:null,pos:0,sub_menus:[]})
          }
        }else{
          for(var contFF = 0; contFF<vetor[contador].sub_menus.length;contFF++){
            if(str == vetor[contador].sub_menus[contFF].id){
              nv2 = true
              for(var i = 0; i<vetor[contador].sub_menus[contFF].sub_menus.length;i++){
                if(i === 0){
                  auxFF.push({name:"Adicionar Novo",id:null, parent_id:null,pos:0,sub_menus:[]})
                }
                auxFF.push({name:vetor[contador].sub_menus[contFF].sub_menus[i].name,id:vetor[contador].sub_menus[contFF].sub_menus[i].id,parent_id:vetor[contador].sub_menus[contFF].sub_menus[i].parent_id,sub_menus:[],pos:i+1,nivel3:true})
              }
              if(auxFF.length === 0){
                auxFF.push({name:"Adicionar Novo", parent_id:null,pos:0,sub_menus:[]})
              }
            }
          }
        }
        if(contador === 0){
          aux.push({name:"Adicionar Novo",id:null, parent_id:null,pos:0,sub_menus:[]})
        }
        aux.push({name:vetor[contador].name,id:vetor[contador].id,parent_id:vetor[contador].parent_id,sub_menus:vetor[contador].sub_menus,pos:contador+1,nivel3:false})
      }
      if(aux.length === 0){
        aux.push({name:"Adicionar Novo", parent_id:null,pos:0,sub_menus:[]})
      }
      
    }catch(err){
      console.log(err)
    }
    if(str === ''){
      setDados(aux)
    }
    else{
      if(nv2===false){
        setDados(auxF)
      }else{
        setDados(auxFF)
      }

    }
  }
  
  function validarDados(id){
    window.location=`/admin/cadastroMenus?id=${id}`
  }

  function salvarNovoMenu(event){
    setNovomenu(event.target.value)
  }
  

  function handleCloseTelaCad(){
      setOpenTelaCad(false)
  }
  function handleOpenTelaCad (){
      setOpenTelaCad(true)
  }

  async function cadastrar(){
    try{
      await api.post("/admin/create-menu",{name:novomenu, parentId:urlID})
      setBool(!bool)
      handleCloseTelaCad()
      toast.success("Menu associado criado com sucesso")
    }catch(err){
      console.log(err)
    }
  }

  async function delet(id){

    try{
      await api.delete(`admin/delete-menu?id=${id}`)
      setBool(!bool)
      toast.success("Menu deletado com sucesso")
    }catch(error){
      console.log(error)
      toast.error("Ocorreu um erro ao tentar excluir, verifique se este menu não possui outros associado")
    }
  }

  function handleCloseTelaEdit(){
    setOpenTelaEdit(false)
  }
  function handleOpenTelaEdit (id,nome){
    
    setMenuNome(nome)
    setMenuIdChoose(id)
    setOpenTelaEdit(true)
  }
  async function editMenu(){
    toast.info("Atualizando menu")
    try{
      await api.post(`admin/update-menu?id=${menuIdChoose}`,{newName:menuNome})
      toast.success("Menu Atualizado")
      setBool(!bool)
      handleCloseTelaEdit()
    }catch(err){
      console.log(err)
      toast.error("Ocorreu um erro")
    }
  }
  function salvarEditMenuNome(event){
    setMenuNome(event.target.value)
  }

  React.useEffect(()=>{
    receberDados()
  },[bool])

  return (
      <div>
        <ToastContainer/>
        <ReactTables
      size={10}
      funcaoOpenTelaEditMenu={handleOpenTelaEdit}
      openTelaEditMenu={openTelaEdit}
      closeTelaEditMenu={handleCloseTelaEdit}
      funcaoOpenTelaCad={handleOpenTelaCad}
      openTelaCad={openTelaCad}
      closeTelaCad={handleCloseTelaCad}
      columns={[
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Actions",
          accessor: "actions",
          sortable: false,
          filterable: false
        }
      ]}
      func={validarDados}
      delet={delet}
      nome={nomeAtual}
      telaEditMenu={
        <div className={classes.containerMenu}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <form>
              <Card login>
              <div>
                <CardBody style={{display:'flex', justifyContent:'center',flexDirection:"column"}}>
                  <div style={{display:'flex',flexDirection:"row"}}>
                    <div style={{marginRight:"10%", width:"80%", marginLeft:"10%"}}>
                    <CustomInput
                    labelText="Nome"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:(
                        salvarEditMenuNome
                      ),
                      defaultValue:(
                        menuNome
                      )
                    }
                    }
                    />
                    </div>
                  </div>
                  <div style={{marginTop:"4%", marginRight:30}}>
                    <Button color="primary" simple size="lg" block onClick={()=> editMenu()}>
                      Salvar
                    </Button>
                  </div>
                </CardBody>
              </div>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    }
      telaCad={
      <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={8} sm={6} md={12}>
          <form>
            <Card login>
              <div style={{height:"290px",marginTop:60}}>
              <CardBody style={{display:'flex', alignItems:'center', justifyContent:'center',flexDirection:"column"}}>
                <div style={{width:300}}>
                  <CustomInput
                    labelText="Nome do novo menu(Obrigatório)"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange:(
                        salvarNovoMenu
                      )
                    }
                  }
                  />
                </div>
                <div style={{marginTop:25, marginRight:30}}>
                <Button color="primary" simple size="lg" block  onClick={()=>cadastrar()} >
                  Salvar
                </Button>
                </div>
              </CardBody>
            </div>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      
    </div>
    }
      data={dados}
      />
      </div>

  );
}