import React from 'react';
import ReactTables from "../Tables/ReactTables";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import MenuRestrict from '../Components/MenuRestrict'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify';
import zIndex from '@material-ui/core/styles/zIndex';

const PurpleCheckbox = withStyles({
  root: {
    color: purple[600],
    '&$checked': {
      color: purple[800],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function EditUser(props){
    const [menus,setMenus] = React.useState([])
    const [visibleDetail, setVisibleDetail] = React.useState(false)
    const [visibleTable, setVisibleTable] = React.useState(false)
    const [data, setData] = React.useState([])
    const [user,setUser] = React.useState({name:"",email:"",cpf:""})
    const [userId, setUserId] = React.useState('')

    async function carregarDados(){
           const vetorResultado = JSON.parse(localStorage.getItem('vetorMenus'));
           setMenus(vetorResultado)
           if(props.location.state === undefined){
                try{
                const response = await api.get("/admin/users-by-corp")
                setData(response.data.users)
                setVisibleTable(true)
                }catch(error){
                    console.log(error)
                    toast.error("Houve um erro ao carregar os dados")
                }
           }else{
               const data = props.location.state
               try{
                const responde = await api.get(`/admin/user-menu-access-admin?id=${data.id}`)
                handleResponse(responde.data.menus)
                
               }catch(err){
                 console.log(err)
               }
               setVisibleDetail(true)
               setVisibleTable(false)
               setUserId(data.id)
               setUser({name:data.name,email:data.email,cpf:data.cpf})
           }
    }

    async function handleResponse(data){
      const vetorResultado = JSON.parse(localStorage.getItem('vetorMenus'));
      console.log(data)
      for(let i =0;i<data.length;i++){
        for(let x =0;x<vetorResultado.length;x++){
          if(data[i].menu_id == vetorResultado[x].id){
            vetorResultado[x].restrict = true;
          }
        }
      }
      setMenus(vetorResultado)
    }

    function handleChecks(id,parent_id,pos){
      let aux = [...menus]
      let irmaoNv2 = false
      let irmaoNv3 = false
      //loop para percorrer o vetor de checkbox
      for(let i=0;i<aux.length;i++){
        //encontro o checkbox que eu cliquei
        if(aux[i].id==id){
          //inverto o valor do checkbox
          aux[i].restrict = !aux[i].restrict
          //verifico se esse checkbox é pai
          if(aux[i].parent_id === null){
              //percorro o vetor de checkbox
              for(let y=0;y<aux.length;y++){
                //verifico se o checkbox possui como pai o checkbox clicado
                if(aux[y].parent_id == id){
                  //mudo o valor do filho a se igualar com o pai
                  aux[y].restrict = aux[pos].restrict == true ? true : false
                  //percorro os filhos do filho do checkbox clicado e igualo eles ao checkbox clicado
                  for(let z=0;z<aux.length;z++){
                    if(aux[z].parent_id == aux[y].id)
                      aux[z].restrict = aux[pos].restrict == true ? true : false
                  }
                }
              }
          }
          //se esse checkbox nao é pai 
          else{
            for(let p = 0;p<aux.length;p++){
              if(aux[i].restrict){
                if(aux[p].id == aux[i].parent_id){
                  aux[p].restrict = true
                  if(aux[p].parent_id!==null){
                    for(let pp = 0;pp<aux.length;pp++){
                      if(aux[p].parent_id === aux[pp].id)
                        aux[pp].restrict = true
                    }
                  }
                }
                else if(aux[p].parent_id == aux[i].id){
                  aux[p].restrict = true
                }
              }else{
                if(aux[p].id == aux[i].parent_id){
                  for(let n = 0;n<aux.length;n++){
                    if(aux[n].parent_id === aux[p].id){
                      if(aux[n].restrict){
                        irmaoNv2 = true
                      }
                    }
                  }
                  irmaoNv2 == true ? aux[p].restrict =aux[p].restrict : aux[p].restrict = false
                  if(aux[p].restrict == false){
                    for(let pp =0;pp<aux.length;pp++){
                      if(aux[pp].parent_id == aux[p].parent_id){
                        if(aux[pp].restrict){
                          irmaoNv3 = true
                        }
                      }
                    }
                    for(let h=0;h<aux.length;h++){
                      if(aux[h].id ==aux[p].parent_id){
                        irmaoNv3 == true ? aux[h].restrict =aux[h].restrict : aux[h].restrict = false
                      }
                    }
                    
                  }
                }
                else if(aux[p].parent_id == aux[i].id){
                  aux[p].restrict = false
                }
              }
            }
          }
        }
      }
      setMenus(aux)
     }

   async function submitMenusRestricts(){
      let aux = []
      for(let i=0;i<menus.length;i++){
        if(menus[i].restrict === true){
          aux.push(menus[i].id)
        }
      }
      let send = {menus:aux,user_id:userId}
      try{
        const response = await api.post('/admin/create-user-menu-access',send)
        .then(()=>window.location.reload())
        console.log(response)
      }catch(error){

      }
      console.log('menus visiveis')
      console.log(send)
      console.log('id do usuario')
      console.log(userId)
    }

    React.useEffect(()=>{
        carregarDados();
    },[props.location.state])
    return(
        <div>
            <ToastContainer/>
            <div style={{display:visibleTable===true? "block" :"none"}}><ReactTables
            size={10}
            data={data}
            nome="Usuarios Associados"
            columns={[
              {
                Header: "Name",
                accessor: "name"
              },
              {
                Header: "Email",
                accessor: "email"
              },
              {
                Header: "CPF",
                accessor: "cpf"
              },
              {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
              }
            ]}
            /></div>
            
            <div style={{display:visibleDetail===true? "block" :"none"}}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                  <form>
                    <Card login>
                    <div style={{height:"100%",width:"100%"}}>
                      <CardBody style={{display:'flex', justifyContent:'center',flexDirection:"column"}}>
                        <div style={{display:'flex',flexDirection:"row",width:"100%",marginBottom:"5%"}}>
                          <div style={{width:"45%",marginRight:"5%"}}>
                          <h1 style={{fontFamily:"roboto",fontWeight:"bold",color:"purple"}}>Usuário</h1>
                          <CustomInput
                          labelText="Nome"
                          id="email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value:(
                              user.name
                            ),readOnly: true,
                          }
                          }
                          />
                          <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                                value:(
                                    user.email
                                  ),readOnly: true,
                            }
                          }
                          />

                          <CustomInput
                            labelText="CPF"
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                                value:(
                                    user.cpf === null ? '' : user.cpf
                                  ),readOnly: true,
                            }
                          }
                          />
                          </div>
                          <div style={{display:"flex",alignItems:"center",flexDirection:"column",width:"50%"}}>
                              <h1 style={{fontFamily:"roboto",fontWeight:"bold",color:"purple"}}>Menus Visíveis</h1>
                              <div style={{width:"100%",overflowY:"scroll",maxHeight:"400px"}}>
                                  <FormGroup style={{width:"100%",display:"flex",flexWrap:"wrap",flexDirection:"row"}}>
                                      {menus.map((menu,key)=>(
                                        <div style={{width:"47%",marginLeft:"3%"}}>
                                          {/*<MenuRestrict 
                                            id={menu.id}
                                            handleChecks={handleChecks} 
                                            nome={menu.nome} 
                                            restrict={menu.restrict} 
                                            key={key}
                                            parent_id={menu.parent_id}
                                          />*/}
                                          <FormControlLabel
                                            key={key}
                                            label={menu.nome}
                                            control={<PurpleCheckbox
                                                    checked={menu.restrict}
                                                    onChange={()=>handleChecks(menu.id,menu.parent_id,key)}
                                                    name={menu.nome}
                                                    color="primary"
                                                    />}
                                            />
                                        </div>
                                      ))}
                                  </FormGroup>
                              </div>
                          </div>
                        </div>
                        <div style={{marginTop:25, marginRight:30}}>
                          <Button color="primary" simple size="lg" block onClick={()=>submitMenusRestricts()}>
                            Salvar
                          </Button>
                        </div>
                        </CardBody>
                        </div>
                              </Card>
                        </form>
                        </GridItem>
                    </GridContainer>
            </div>
        </div>
    );
}