import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTables from "../Tables/ReactTables"
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import api from "services/api";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const styles2 = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  container:{
    width:"60%",
    marginLeft:"20%",
    height:"100%",
  }
}
const useStyles2 = makeStyles(styles)
const useStyles = makeStyles(styles2);


export default function Empresas() {
  const [dados, setDados] = React.useState([])
  const [admins, setAdmins] = React.useState([])
  const [menus, setMenus] = React.useState([])
  const [url, setURL] = React.useState(null)
  const [menusID, setMenusID] = React.useState([])
  const [menusBIS, setMenusBIS] = React.useState([])
  const [menusBisID, setMenusBisID] = React.useState([])
  const [corpID, setCorpID] = React.useState([])
  const [bool, setBool] = React.useState(false)
  const [bol, setBol] = React.useState(false)
  const [obj, setObj] = React.useState([])
  const [adminChoose, setAdminChoose] = React.useState('Selecione um Admin')
  const [menuChoose, setMenuChoose] = React.useState('Selecione um Menu')
  const [deleteBI, setDeleteBI] = React.useState('Delete uma BI')
    async function carregarDados(){
      try{
        const vetor = []
        const response2 = await api.get("/admin/users?is_admin=1")
        const data = response2.data.users
        var aux1 = []
        for(var cont = 0; cont < data.length; cont++){
          aux1.push(data[cont].email)
          vetor.push({email:data[cont].email, id:data[cont].id})
        }
        setAdmins(aux1)
        setObj(vetor)
      }catch(error){
        console.log(error)
      }
      try{
        var aux = []
        const response = await api.get("/admin/corps")
        const vetor = response.data.corps
        for(var count=0;count<vetor.length;count++){
          if(count === 0){
            aux.push({name:"Adicionar Novo", parent_id:null,pos:0,sub_menus:[]})
          }
          aux.push({name:vetor[count].name,id:vetor[count].id ,cnpj:vetor[count].cnpj, address:vetor[count].address, admin_id:vetor[count].admin_id})
        }
        if(aux.length === 0){
          aux.push({name:"Adicionar Novo", pos:0})
        }
        setDados(aux)
      }catch(err){
        console.log(err)
      }
    }
    
    React.useEffect(()=>{
      carregarDados();
    },[bool,bol])
    async function empresa(ID){
      try{
        toast.info("Deletando empresa")
        await api.delete(`/admin/delete-corp?id=${ID}`)
        
        setBol(!bool)
      }catch(err){
        console.log(err)
        toast.error("Erro ao deletar empresa")
      }
    }
    function salvarUrl(event){
      setURL(event.target.value)
    }

    async function criarBI(){
      toast.info("Criando Bi")
      const menuesc = menuChoose
      var menusArray = menus
      var id = null
      for(var i=0; i<menusArray.length;i++){
        if(menuesc === menusArray[i]){
          id = menusID[i]
        }
      }
      try{
        await api.post("/admin/create-bi",{
          corp_id:corpID,
          menu_id: id,
          url: url
        })
        toast.success("Salvo com sucesso")
        handleCloseTelaEditBI()
      }
      catch(err){
        toast.error("Erro ao criar Bi")

      }
    }

    const classes = useStyles();
    const classes2 = useStyles2();
    const [novaEmpresa, setNovaEmpresa] = React.useState({
      nome:null,
      cnpj:null,
      endereco:null,
      admin_id:null
    });

    function salvarNomeEmpresa(event){
      setNovaEmpresa({
          nome:event.target.value,
          cnpj:novaEmpresa.cnpj,
          endereco:novaEmpresa.endereco,
          admin_id:novaEmpresa.admin_id,
        })
    }
    const cnpjMask = value => {
      return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
    }
    function salvarCnpjEmpresa(event){
      setNovaEmpresa({
        nome:novaEmpresa.nome,
        cnpj:cnpjMask(event.target.value),
        endereco:novaEmpresa.endereco,
        admin_id:novaEmpresa.admin_id,
      })
    }
    function salvarEndEmpresa(event){
      setNovaEmpresa({
        nome:novaEmpresa.nome,
        cnpj:novaEmpresa.cnpj,
        endereco:event.target.value,
        admin_id:novaEmpresa.admin_id,
      })
    }

    
    function salvarId(){
      var aux = obj
      var chamou = false
          for(var count =0; count<aux.length; count++){
            if(aux[count].email === adminChoose){
              submeter(aux[count].id)
              chamou = true
            }
          }
          if(chamou === false){
            toast.error("Selecione um admin")
          }
    }
    
    async function submeter(id){
      if(novaEmpresa.nome == null || novaEmpresa.cnpj == null || novaEmpresa.endereco==null){
        toast.error("Confira se todos os campos estão preenchidos")
      }
      else if(novaEmpresa.cnpj.length!==18){
        toast.error("Confira se o campo de cnpj está correto")
      }
      else{
        try{
          toast.info("criando empresa")
            await api.post("/admin/create-corp",{
            name:novaEmpresa.nome,
            cnpj:novaEmpresa.cnpj,
            address:novaEmpresa.endereco,
            admin_id:id
          })
          toast.success("Empresa criado com sucesso")
          setNovaEmpresa({
            nome:null,
            cnpj:null,
            endereco:null,
            admin_id:null
          })
          handleCloseTelaCad()
        }catch(error){
          console.log(error)
          toast.error("Verifique os dados")
        }
      }
      setBool(!bool)
    }
    async function removeBi(){
      toast.info("Deletando")
      var vetBis = menusBIS
      var vetBisID = menusBisID
      let menu_id
      for(var i =0; i<vetBis.length; i++){
        if(deleteBI === vetBis[i]){
          menu_id = vetBisID[i]
        }
      }
      try{
        await api.delete(`/admin/delete-bi?menu_id=${menu_id}&corp_id=${corpID}`)
        toast.success("Bi removida")
      }catch(err){
        console.log(err)
        toast.error("Ocorreu um erro ao deletar Bi")
      }
      handleCloseTelaEditBI()
    }
    const [openTelaCad, setOpenTelaCad] = React.useState(false)
    function handleCloseTelaCad(){
      setOpenTelaCad(false)
      setAdminChoose("Selecione um Admin")
    }
    function handleOpenTelaCad (){
      setOpenTelaCad(true)
    }

    const [openTelaEdit, setOpenTelaEdit] = React.useState(false)
    function handleCloseTelaEdit(){
      setOpenTelaEdit(false)
    }
    const [corp, setCorp] = React.useState()
    function handleOpenTelaEdit(id){
      for(var i = 1; i<dados.length; i++){
        if(dados[i].id === id){
          setNovoNome(dados[i].name)
          setNovoEndereco(dados[i].address)
          setNovoCnpj(dados[i].cnpj)
          for(var t = 0; t<obj.length;t++){
            if(obj[t].id === dados[i].admin_id){
              setAdminChoose(obj[t].email)
              t = obj.length + 1
            }
            else{
              setAdminChoose('Admin não encontrado')
            }
          }
        }
      }
      setOpenTelaEdit(true)
      setCorp(id)
    }

    const [novoCnpj, setNovoCnpj] = React.useState('');
    const [novoNome, setNovoNome] = React.useState('');
    const [novoEndereco, setNovoEndereco] = React.useState('');
    function salvarNovoCnpj(event){
      setNovoCnpj(cnpjMask(event.target.value))
    }
    function salvarNovoNome(event){
      setNovoNome(event.target.value)
    }
    function salvarNovoEndreco(event){
      setNovoEndereco(event.target.value)
    }

    const [openTelaEditBI, setOpenTelaEditBI] = React.useState(false)
    function handleCloseTelaEditBI(){
      setOpenTelaEditBI(false)
    }

    async function alterarCorp(){
      let novo_admin_id;
      for(var t = 0; t<obj.length;t++){
        if(obj[t].email === adminChoose){
          novo_admin_id = obj[t].id
        }
      }

      try{
        await api.post(`/admin/update-corp?id=${corp}`,{newCNPJ:novoCnpj, newAddress:novoEndereco, 
          newAdmin_id:novo_admin_id,newName:novoNome})
        toast.success("Dados atualizados com sucesso")
        handleCloseTelaEdit()
      }catch(err){
        console.log(err)
        toast.error("Verifique os dados")
      }
    }
    async function handleOpenTelaEditBI (id){

      toast.info("Carregando")
      const response = await api.get("/admin/menus")
      const menusArray = response.data.menus
      var vetor = []
      var menusID = []
      for(var i =0; i<menusArray.length; i++){
        if(menusArray[i].sub_menus.length===0){
          vetor.push(menusArray[i].name)
          menusID.push(menusArray[i].id)
        } else{
              for(var t = 0; t<menusArray[i].sub_menus.length; t++){
                if(menusArray[i].sub_menus[t].sub_menus.length===0){
                  vetor.push(menusArray[i].sub_menus[t].name)
                  menusID.push(menusArray[i].sub_menus[t].id)
                }
                else{
                  for(var u = 0; u<menusArray[i].sub_menus[t].sub_menus.length;u++){
                    vetor.push(menusArray[i].sub_menus[t].sub_menus[u].name)
                    menusID.push(menusArray[i].sub_menus[t].sub_menus[u].id)
                  }
                }
              }
            }
      }
      setMenusID(menusID)
      setMenus(vetor)
      setCorpID(id)
      let menusComBis = [];
      let menusComBisID = [];
      try{
        const response = await api.get(`/admin/corp-bis?corp_id=${id}`)
        const dados = response.data.bis
        for(var a =0; a<dados.length; a++){
          if(dados[a].bi !== null && dados[a].bi !== undefined){
            menusComBis.push(dados[a].name)
            menusComBisID.push(dados[a].id)
          }
        }
      }catch(err){
        console.log(err)
      }
      setMenusBIS(menusComBis)
      setMenusBisID(menusComBisID)
      setOpenTelaEditBI(true)
    }
    const [adminSelect, setAdminSelect] = React.useState(null)
    const handleAdminSelect = event => {
      setAdminSelect(event.target.value);
      for(var i = 0; i<admins.length;i++){
        if(event.target.value === i){
          setAdminChoose(admins[i])
        }
      }
    }
    const [menuSelect, setMenuSelect] = React.useState(null)
    const handleMenuSelect = event => {
      setMenuSelect(event.target.value);
      for(var i = 0; i<menus.length;i++){
        if(event.target.value === i){
          setMenuChoose(menus[i])
        }
      }
    }
    const [deleteSelect, setDeleteSelect] = React.useState(null)
    const handleDeleteSelect = event => {
      setDeleteSelect(event.target.value);
      for(var i = 0; i<menusBIS.length;i++){
        if(event.target.value === i){
          setDeleteBI(menusBIS[i])
        }
      }
    }
    return (
      <div>
      <ToastContainer/>
        <ReactTables
        size={10}
        funcaoOpenTelaCad={handleOpenTelaCad}
        openTelaCad={openTelaCad}
        closeTelaCad={handleCloseTelaCad}
        funcaoOpenTelaEditBI={handleOpenTelaEditBI}
        openTelaEditBI={openTelaEditBI}
        closeTelaEditBI={handleCloseTelaEditBI}
        funcaoOpenTelaEdit={handleOpenTelaEdit}
        openTelaEdit={openTelaEdit}
        closeTelaEdit={handleCloseTelaEdit}
        telaEditBI={
            <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <form>
                  <Card login>
                    <div style={{height:"440px"}}>
                    <CardHeader style={{justifyContent:"flex-start",display:'flex',flexDirection:"column",marginRight:"3%",marginTop:"4%"}}>
                    <div style={{display:"flex", alignItems:"column",width:"370"}}>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={5} lg={12}>
                                <FormControl fullWidth className={classes2.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes2.selectLabel}>
                                  Selecione um Menu
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes2.selectMenu
                                  }}
                                  classes={{
                                    select: classes2.select
                                  }}
                                  value={menuSelect}
                                  onChange={handleMenuSelect}
                                  inputProps={{
                                    name: "menuSelect",
                                    id: "menu-select"
                                  }}
                                >
                                {menus.map((menu,key)=>
                                  <MenuItem
                                  classes={{
                                    root: classes2.selectMenuItem,
                                    selected: classes2.selectMenuItemSelected
                                  }}
                                  value={key}
                                  >
                                  {menu}
                                </MenuItem>
                                )}
                                </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                          </div>
                      <div style={{width:"370"}}>
                        <CustomInput
                          labelText="Insira a URL"
                          id="email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange:(
                              salvarUrl
                            )}}
                        />
                      </div>
                      <div style={{marginTop:22}}>
                      <Button color="primary" simple size="lg" block onClick={()=>criarBI()}>
                        Salvar
                      </Button>
                      </div>
                    </CardHeader>
                    <CardFooter style={{display:"flex", flexDirection:"column",marginTop:"-20"}}>
                            <GridContainer style={{marginLeft:3}}>
                              <GridItem xs={12} sm={6} md={5} lg={11}>
                                <FormControl fullWidth className={classes2.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes2.selectLabel}>
                                  Delete uma BI
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes2.selectMenu
                                  }}
                                  classes={{
                                    select: classes2.select
                                  }}
                                  value={deleteSelect}
                                  onChange={handleDeleteSelect}
                                  inputProps={{
                                    name: "deleteSelect",
                                    id: "delete-select"
                                  }}
                                >
                                {menusBIS.map((menuBI,key)=>
                                  <MenuItem
                                  classes={{
                                    root: classes2.selectMenuItem,
                                    selected: classes2.selectMenuItemSelected
                                  }}
                                  value={key}
                                  >
                                  {menuBI}
                                </MenuItem>
                                )}
                                </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                      <div >
                        <Button color="primary" simple size="lg" block onClick={()=>removeBi()}>
                          Deletar
                        </Button>
                      </div>
                      </CardFooter>
                  </div>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        }
        telaCad={
          <div style={{ width:"100%",marginLeft:"5%"}}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={10}>
                <form>
                  <Card login>
                    <div style={{height:"500px", width:"480px"}}>
                    <CardBody style={{display:'flex', alignItems:'block', justifyContent:'space-between'}}>
                      <div style={{width:"370", marginTop:50}}>
                      <CustomInput
                        labelText="Nome Da empresa(Obrigatório)"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange:(
                            salvarNomeEmpresa
                          )
                        }
                      }
                      />
                      <CustomInput
                        labelText="CNPJ Da Empresa(Obrigatório)"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange:(
                            salvarCnpjEmpresa
                          ),
                          value:(
                            novaEmpresa.cnpj
                          )
                        }
                      }
                      />
                      <CustomInput
                        labelText="Endereço Da Empresa(Obrigatório)"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange:(
                            salvarEndEmpresa
                          )
                        }
                      }
                      />
                      <div style={{display:"flex", alignItems:"row", justifyContent:"center"}}>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={5} lg={12}>
                                <FormControl fullWidth className={classes2.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes2.selectLabel}>
                                  Selecione um Admin
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes2.selectMenu
                                  }}
                                  classes={{
                                    select: classes2.select
                                  }}
                                  value={adminSelect}
                                  onChange={handleAdminSelect}
                                  inputProps={{
                                    name: "adminSelect",
                                    id: "admin-select"
                                  }}
                                >
                                {admins.map((admin,key)=>
                                  <MenuItem
                                  classes={{
                                    root: classes2.selectMenuItem,
                                    selected: classes2.selectMenuItemSelected
                                  }}
                                  value={key}
                                  >
                                  {admin}
                                </MenuItem>
                                )}
                                </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                          </div>
                      
                      <div style={{marginTop:20}}>
                        <Button color="primary" simple size="lg" block onClick={()=>{salvarId()}}>
                          Salvar
                        </Button>
                      </div>
                      </div>
                    </CardBody>
                  </div>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        }
        telaEdit={
          <div className={classes.container}>
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
                  <form>
                    <Card login>
                    <div style={{height:"100%",width:"100%"}}>
                      <CardBody style={{display:'flex', justifyContent:'center',flexDirection:"column"}}>
                        <div style={{display:'flex',flexDirection:"column"}}>
                          <div style={{marginRight:5}}>
                            <CustomInput
                              labelText="Informe o novo cnpj"
                              id="email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange:(
                                  salvarNovoCnpj
                                ),
                                defaultValue:(
                                  novoCnpj
                                ),
                                value:(
                                  novoCnpj
                                )}}
                            />
                            <CustomInput
                              labelText="Informe o novo nome"
                              id="email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange:(
                                  salvarNovoNome
                                ),
                                defaultValue:(
                                  novoNome
                                )}}
                            />
                            <CustomInput
                              labelText="Informe o novo endereço"
                              id="email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange:(
                                  salvarNovoEndreco
                                ),
                                defaultValue:(
                                  novoEndereco
                                )}}
                            />
                             <div style={{display:"flex", alignItems:"row", justifyContent:"center"}}>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={5} lg={12}>
                                <FormControl fullWidth className={classes2.selectFormControl}>
                                <InputLabel htmlFor="simple-select" className={classes2.selectLabel}>
                                  Selecione um Admin
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes2.selectMenu
                                  }}
                                  classes={{
                                    select: classes2.select
                                  }}
                                  value={adminSelect}
                                  onChange={handleAdminSelect}
                                  inputProps={{
                                    name: "adminSelect",
                                    id: "admin-select"
                                  }}
                                >
                                {admins.map((admin,key)=>
                                  <MenuItem
                                  classes={{
                                    root: classes2.selectMenuItem,
                                    selected: classes2.selectMenuItemSelected
                                  }}
                                  value={key}
                                  >
                                  {admin}
                                </MenuItem>
                                )}
                                </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                          </div>
                          </div>
                          <div style={{marginTop:25, marginRight:30}}>
                            <Button color="primary" simple size="lg" block onClick={()=>alterarCorp()}>
                              Salvar
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </div>
                    </Card>
                  </form>
              </GridItem>
            </GridContainer>
          </div>
        }
        columns={[
          {
            Header: "Name",
            accessor: "name"
          },
          {
            Header: "CNPJ",
            accessor: "cnpj"
          },
          {
            Header: "Address",
            accessor: "address"
          },
          {
            Header: "Admin",
            accessor: "admin"
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
        data={dados}
        func={empresa}
        nome="Empresas Cadastradas"
        />
      </div>
    );
}
